import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Icon, Button } from '@material-ui/core/';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import { push } from 'connected-react-router';

// import DDlIdioma from '../../components/Idioma/dropDownListIdioma';
import { logError } from '../../helpers/logger';
import { selectPoi } from '../../actions/lists';
// animales
import imgGalloBig from '../../assets/images/animales-grandes/gallo_big.png';
import imgConejoBig from '../../assets/images/animales-grandes/conejo_big.png';
import imgElefanteBig from '../../assets/images/animales-grandes/elefante_big.png';
import imgCerdoBig from '../../assets/images/animales-grandes/cerdo_big.png';
import imgPerroBig from '../../assets/images/animales-grandes/perro_big.png';
import imgPatoBig from '../../assets/images/animales-grandes/pato_big.png';
import imgJirafaBig from '../../assets/images/animales-grandes/jirafa_big.png';
import imgCaballoBig from '../../assets/images/animales-grandes/caballo_big.png';
import imgMariposaBig from '../../assets/images/animales-grandes/mariposa_big.png';
// frutas
import imgPiñaBig from '../../assets/images/frutas-grandes/piña_big.png';
import imgPeraBig from '../../assets/images/frutas-grandes/pera_big.png';
import imgManzanaBig from '../../assets/images/frutas-grandes/manzana_big.png';
import imgUvaBig from '../../assets/images/frutas-grandes/uva_big.png';
import imgLimonBig from '../../assets/images/frutas-grandes/limon_big.png';
import imgSandiaBig from '../../assets/images/frutas-grandes/sandia_big.png';
import imgNaranjaBig from '../../assets/images/frutas-grandes/naranja_big.png';
import imgArandanoBig from '../../assets/images/frutas-grandes/arandano_big.png';
// estilos
import './generales.scss';
import './resultadoEsta.scss';
import { getTotemData } from '../../config/kiosk';

class ResultadoEsta extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      piso: '',
      color: '',
      zona: '',
      figura: [],
      parking: '',
      secondaryName: '',
      cash: false
    };
  }

  static defaultProps = {
    match: {},
    dispatch: PropTypes.any
  };

  static propTypes = {
    match: PropTypes.object,
    dispatch: PropTypes.any
  };

  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    const { id } = this.props.match.params;

    switch (id) {
      case ':selGallo':
        this.setState({
          piso: '6',
          color: 'BLANCO',
          zona: 'paris - ripley',
          parking: 'E6A',
          secondaryName: '',
          figura: imgGalloBig
        });
        break;
      case ':selConejo':
        this.setState({
          piso: '5',
          color: 'CAFE',
          zona: 'paris - ripley',
          parking: 'E5A',
          secondaryName: '',
          figura: imgConejoBig
        });
        break;
      case ':selElefante':
        this.setState({
          piso: '4',
          color: 'AZUL',
          zona: 'paris - ripley',
          parking: 'E4A',
          secondaryName: '',
          figura: imgElefanteBig
        });
        break;
      case ':selCerdo':
        this.setState({
          piso: '3',
          color: 'ROSADO',
          zona: 'paris - ripley',
          parking: 'E3A',
          secondaryName: '',
          figura: imgCerdoBig
        });
        break;
      case ':selPerro':
        this.setState({
          piso: '2',
          color: 'ROJO',
          zona: 'paris - ripley',
          parking: 'E2A',
          secondaryName: '',
          figura: imgPerroBig
        });
        break;
      case ':selPato':
        this.setState({
          piso: '1',
          color: 'AMARILLO',
          zona: 'paris - ripley',
          parking: 'E1A',
          secondaryName: '',
          figura: imgPatoBig
        });
        break;
      case ':selJirafa':
        this.setState({
          piso: '-1',
          color: 'NARANJO',
          zona: 'paris - ripley',
          parking: 'E-1A',
          secondaryName: '',
          figura: imgJirafaBig
        });
        break;
      case ':selCaballo':
        this.setState({
          piso: '-2',
          color: 'VERDE',
          zona: 'paris - ripley',
          parking: 'E-2A',
          secondaryName: '',
          figura: imgCaballoBig
        });
        break;
      case ':selMariposa':
        this.setState({
          piso: '-3',
          color: 'CELESTE',
          zona: 'paris - ripley',
          parking: 'E-3A',
          secondaryName: '',
          figura: imgMariposaBig
        });
        break;
      case ':selPiña':
        this.setState({
          piso: '2',
          color: 'DORADO',
          zona: 'blvd - falabella',
          parking: 'E2F',
          secondaryName: 'Express',
          figura: imgPiñaBig
        });
        break;
      case ':selPera':
        this.setState({
          piso: '1',
          color: 'VERDE',
          zona: 'blvd - falabella',
          parking: 'E1F',
          secondaryName: 'Express / Andén',
          figura: imgPeraBig
        });
        break;
      case ':selManzana':
        this.setState({
          piso: '-1',
          color: 'ROJO',
          zona: 'blvd - falabella',
          parking: 'E-1F',
          secondaryName: '',
          figura: imgManzanaBig
        });
        break;
      case ':selUva':
        this.setState({
          piso: '-2',
          color: 'MORADO',
          zona: 'blvd - falabella',
          parking: 'E-2F',
          secondaryName: '',
          figura: imgUvaBig
        });
        break;
      case ':selLimon':
        this.setState({
          piso: '-3',
          color: 'AMARILLO',
          zona: 'blvd - falabella',
          parking: 'E-3F',
          secondaryName: '',
          figura: imgLimonBig
        });
        break;
      case ':selSandia':
        this.setState({
          piso: '-4',
          color: 'ROSADO',
          zona: 'blvd - falabella',
          parking: 'E-4F',
          secondaryName: '',
          figura: imgSandiaBig
        });
        break;
      case ':selNaranja':
        this.setState({
          piso: '-5',
          color: 'NARANJO',
          zona: 'blvd - falabella',
          parking: 'E-5F',
          secondaryName: '',
          figura: imgNaranjaBig
        });
        break;
      case ':selArandano':
        this.setState({
          piso: '-6',
          color: 'AZUL',
          zona: 'blvd - falabella',
          parking: 'E-6F',
          secondaryName: '',
          figura: imgArandanoBig
        });
        break;
      default:
        this.setState({
          piso: '0',
          color: '',
          zona: '',
          secondaryName: '',
          figura: ''
        });
        break;
    }
  }

  handleToogleStateCash = () => {
    this.setState(prevState => ({
      cash: !prevState.cash
    }));
  };

  handleGoParkingLot = (parkingId, zona, piso, color, secondaryName) => {
    const { dispatch } = this.props;

    const item = {
      id: '',
      name: `${piso} ${color} - ${secondaryName} Zona ${zona}`,
      icon: 'icon-Bancos',
      isSelectable: false,
      mallId: 1,
      poiTypeId: 99,
      slug: null,
      storeCount: 1,
      urlLanding: null,
      bgColorClass: 'bg-VERDE',
      originPath: '/index',
      destinPath: '/ResultadoMapa',
      mapTitle: 'RUTA-ESTACIONAMIENTO',
      suc: '',
      siteFR: zona === 'paris - ripley' ? 'pr' : 'fb',
      DestinsucParking: parkingId,
      noShowPath: true,
      atm: false
    };

    dispatch(selectPoi(item));
    dispatch(push(`/${getTotemData(true).deviceName}/ResultadoMapa`));
  };

  render() {
    const origin = getTotemData(true).deviceName;

    try {
      const { piso, color, zona, parking, figura, secondaryName } = this.state;

      ReactGA.event({
        category: 'find_your_car',
        action: 'no_recuerdo_mi_patente',
        label: `piso=${piso};color=${color};zona=${zona};figura=${figura}; Arauco+;`
      });
      ReactGA4.event({
        category: 'find_your_car',
        action: 'no_recuerdo_mi_patente',
        label: `piso=${piso};color=${color};zona=${zona};figura=${figura}; Arauco+;`
      });

      return (
        <div className={`fnd-full fnd-${color}-main`}>
          <div className={`cabecera-resultados bg-${color}-header`}>
            <div className='barra barra-resultado'>
              <Link
                className='btn-volver'
                to={
                  window.location.pathname.indexOf('atm-route') === 1
                    ? `/${origin}/atm-route`
                    : `/${origin}/Carfinder/BusquedaEstacionamiento`
                }
              >
                <Icon className='m-r'>arrow_back</Icon>
                <div>
                  <Trans i18nKey='busqueda_estacionamiento_resultado_btn_volver' />
                </div>
              </Link>
              {/* <DDlIdioma /> */}
            </div>
          </div>
          <div className='info-piso'>
            <div className='numero-piso'>{piso}</div>
            <div className='color-piso'>
              <Trans i18nKey={`busqueda_patente_encontrado_color_${color}`} />
              {secondaryName && ` - ${secondaryName}`}
            </div>
            <div className='adorno' />
            <div className='titulo-estacionamiento'>
              <Trans i18nKey='busqueda_patente_encontrado_titulo_estacionamiento' />
            </div>
            <div className='zona-estacionamiento'>{zona}</div>
          </div>
          <div className='figura-piso'>
            <img src={figura} alt='figura' className='img-figura' />
          </div>

          {window.location.pathname.indexOf('atm-route') === 1 ? (
            <React.Fragment>
              <Link to='/atm-route'>
                <Button
                  id='btnEstacionamientos'
                  variant='outlined'
                  fullWidth
                  color='primary'
                  className={`btn-estacionamiento-buscar  ${
                    color === 'BLANCO' ? 'cajero-black' : 'cajero'
                  } max-w-500`}
                >
                  <Trans i18nKey='busqueda_cajero' />
                </Button>
              </Link>
              <span>
                <input
                  className={`checkBoxCustom ${
                    color === 'BLANCO' ? 'checkBoxCustomBlack' : ''
                  }`}
                  type='checkbox'
                  onChange={this.handleToogleStateCash}
                />
                <span>Prefiero pagar en efectivo</span>
              </span>
            </React.Fragment>
          ) : (
            <div className='row justify-content-center'>
              <div
                onClick={() =>
                  this.handleGoParkingLot(
                    parking,
                    zona,
                    piso,
                    color,
                    secondaryName
                  )
                }
                className='col-8'
              >
                <div className={`txt-${color}-main buttonGo`}>
                  <div>
                    <Trans i18nKey='busqueda_patente_encontrado_btn_como_llegar' />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    } catch (err) {
      logError('[ResultadoEsta].[render]', {
        error: err,
        path: 'src/containers/CarFinder/resultadoEsta.jsx'
      });

      return undefined;
    }
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

export default connect(mapDispatchToProps)(ResultadoEsta);
