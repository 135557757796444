import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Modal, Fade, Icon } from '@material-ui/core';

import { getTotemData } from '../../config/kiosk';
import './ModalUser.scss';

export class ModalPayByPhone extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      cash: false
    };
  }

  static propTypes = {
    close: PropTypes.func.isRequired
  };

  handleToogleStateCash = () => {
    this.setState(prevState => ({
      cash: !prevState.cash
    }));
  };

  render() {
    const divStyle = {
      width: '96%',
      marginLeft: '4%',
      fontSize: '16px !important'
    };
    const { cash } = this.state;
    const { close } = this.props;
    const kioskOrigin = getTotemData(true).deviceName;

    return (
      <Modal
        open='true'
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        style={{ zIndex: '9999999999' }}
      >
        <Fade in='true'>
          <div className='modal-login'>
            <div className='header-pay'>
              <span className='close-button-x' onClick={() => close()}>
                <Icon>close</Icon>
              </span>
            </div>

            <div className='TitleConteiner'>
              <div className='content'>
                <h1>¡No te olvides!</h1>
                <br />
                <div style={{ marginTop: '-5%' }}>
                  <div style={divStyle}>
                    <p>
                      Debes seleccionar el <b>punto más cercano </b> al lugar
                      donde estas <b>estacionado.</b>
                    </p>
                  </div>
                </div>
                <hr />
                <div>
                  <div style={divStyle}>
                    <p>
                      Luego del pago tienes de{' '}
                      <b>10 min para salir del mall.</b>
                    </p>
                  </div>
                </div>
                <br />
                <p>
                  <input
                    type='checkbox'
                    onChange={this.handleToogleStateCash}
                    className='check-efectivo'
                  />
                  <span>Prefiero pagar en efectivo</span>
                </p>
              </div>
            </div>

            <div className='div-btn-gen'>
              <Link
                to={{
                  pathname: `/${kioskOrigin}/atm-route`,
                  state: { stateCash: cash }
                }}
              >
                <button type='button' className='btn-gen'>
                  Aceptar
                </button>
              </Link>
            </div>
          </div>
        </Fade>
      </Modal>
    );
  }
}

export default ModalPayByPhone;
