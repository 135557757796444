import React from 'react';
import { connect } from 'react-redux';
import { Icon } from '@material-ui/core';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';

import StatusBar from '../../components/statusBar';
import { logError } from '../../helpers/logger';
import { selectPoi } from '../../actions/lists';
import { getTotemData } from '../../config/kiosk';

import styles from './styles';
import './carFinded.scss';

const SEARCH_ROUTE = `/BusquedaPorPatente`;
const SEARCH_ROUTE_CAJERO = `/BusquedaPorPatenteCajero`;

class CarFinded extends React.Component {
  constructor(props) {
    super(props);

    const { match } = this.props;

    this.state = {
      cajero: match.params.cajero
    };
  }

  static defaultProps = {
    floorData: null,
    allFloor: {},
    match: {}
  };

  static propTypes = {
    floorData: PropTypes.object,
    allFloor: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    patent: PropTypes.string.isRequired,
    match: PropTypes.object
  };

  componentDidMount = () => {
    const { floorData, dispatch, match } = this.props;
    const { cajero } = match.params;

    if (cajero) {
      const item = {
        id: '794',
        name: 'Cajeros Estacionamientos',
        icon: 'icon-Bancos',
        isSelectable: false,
        mallId: 1,
        poiTypeId: 5,
        slug: null,
        storeCount: 1,
        urlLanding: null,
        bgColorClass: 'bg-MINT',
        originPath: '/index',
        destinPath: '/ResultadoMapa',
        mapTitle: 'RUTA-CAJERO',
        suc: floorData.suc || '',
        noShowPath: true,
        atm: true,
        money: false
      };

      dispatch(selectPoi(item));

      const url = window.location.href;
      const queryParams = new URLSearchParams(url);

      queryParams.delete('true');
      dispatch(push(`/${getTotemData(true).deviceName}/ResultadoMapa`));
    }

    if (!floorData) {
      if (!cajero) {
        dispatch(push(SEARCH_ROUTE));
      } else {
        dispatch(push(SEARCH_ROUTE_CAJERO));
      }
    }
  };

  handleBack = () => {
    const { cajero } = this.state;
    const { patent } = this.props;

    if (cajero) {
      window.location.href = `/${
        getTotemData(true).deviceName
      }/Carfinder/Results/${patent}/true`;
    } else {
      window.history.back();
    }
  };

  handleGoToMyCar = allFloor => {
    const mallId = localStorage.getItem('cl.araucoplus.mallId');
    const { dispatch } = this.props;
    const { spot, parkingName } = allFloor;
    const item = {
      id: '',
      name: `Estacionamiento ${parkingName}`,
      icon: 'icon-Automotriz',
      isSelectable: false,
      mallId,
      poiTypeId: 99,
      slug: null,
      storeCount: 1,
      urlLanding: null,
      bgColorClass: 'bg-VERDE',
      originPath: '/index',
      destinPath: '/ResultadoMapa',
      mapTitle: 'Estacionamiento',
      suc: '',
      DestinsucParking: spot.suc,
      siteFR: parkingName === 'PARIS-RIPLEY' ? 'pr' : 'fb',
      originLabel: ''
    };

    dispatch(selectPoi(item));
    dispatch(push(`/${getTotemData(true).deviceName}/ResultadoMapa`));
  };

  render() {
    try {
      const { floorData, patent, allFloor } = this.props;

      if (!floorData) {
        return null;
      }

      const {
        backgroundColor,
        floor,
        colorName,
        description,
        headerColor,
        exitName,
        imageSrc
      } = floorData;

      const { pillar } = allFloor || {};

      ReactGA.event({
        category: 'find_your_car',
        action: 'auto_encontrado',
        label: `patente=${patent};piso=${floor};color=${colorName},zona=${description}; Arauco+; `
      });
      ReactGA4.event({
        category: 'find_your_car',
        action: 'auto_encontrado',
        label: `patente=${patent};piso=${floor};color=${colorName},zona=${description}; Arauco+; `
      });

      return (
        <div
          className='car-finded'
          style={{
            ...styles.background,
            backgroundColor
          }}
        >
          <StatusBar backgroundColor={headerColor} style={styles.backContainer}>
            <div className='cabecera-resultados'>
              <div className='barra'>
                <div className='btn-volver' onClickCapture={this.handleBack}>
                  <Icon className='m-r'>arrow_back</Icon>
                  <div>
                    <Trans i18nKey='busqueda_patente_encontrado_volver' />
                  </div>
                </div>
              </div>
            </div>
          </StatusBar>
          <section style={styles.firstSecction}>
            <div style={styles.firstText} className='titulo'>
              <Trans i18nKey='busqueda_patente_encontrado_titulo' />
            </div>
            <span style={styles.secondText} className='patente'>
              <Trans i18nKey='busqueda_patente_encontrado_patente' />{' '}
              <b>{patent}</b>{' '}
            </span>
          </section>
          <section>
            <span style={styles.floorWrapper} className='result-content'>
              <span style={styles.flootTitle} className='title-result'>
                <Trans i18nKey='busqueda_patente_encontrado_se_encuentra' />
              </span>
              {pillar?.code ? (
                <div className='pillar container_number'>
                  <div className='container_number reverse_column'>
                    <span className='pillar_name'>
                      <Trans i18nKey='label_piso' />
                    </span>
                    <span style={styles.flootNumber} className='floor-number'>
                      {floor}
                    </span>
                  </div>

                  <span className='pillar_separator' />
                  <div className='container_number reverse_column'>
                    <span className='floor-name'>{pillar.text}</span>
                    <span style={styles.flootNumber} className='floor-code'>
                      {pillar.code}
                    </span>
                  </div>
                </div>
              ) : (
                <span style={styles.flootNumber} className='floor-number'>
                  {floor}
                </span>
              )}
              <span style={styles.colorText} className='floor-color'>
                <Trans
                  i18nKey={`busqueda_patente_encontrado_color_${colorName}`}
                />
              </span>
              <span style={styles.divider} className='divider' />
              <span className='title-parking'>
                <Trans i18nKey='busqueda_patente_encontrado_titulo_estacionamiento' />
              </span>
              <span style={styles.parkingText} className='description-parking'>
                {exitName}
              </span>
              {imageSrc && (
                <span className='img-parking'>
                  <img style={styles.image} src={imageSrc} alt='animal' />
                </span>
              )}
              {allFloor && (
                <span
                  onClick={() => this.handleGoToMyCar(allFloor)}
                  className='car-button-finded'
                >
                  <Trans i18nKey='busqueda_patente_encontrado_boton_llevar' />
                </span>
              )}
            </span>
          </section>
        </div>
      );
    } catch (err) {
      logError('[CarFinded].[render]', {
        error: err,
        path: 'src/containers/CarFinded/index.jsx'
      });

      return undefined;
    }
  }
}

const mapStateToProps = state => ({
  patent: state.findYourCar.data.patent,
  dataImages: state.findYourCar.data.parkingImages,
  floorData: state.findYourCar.data.floorData,
  allFloor: state.findYourCar.data.allFloor
});

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CarFinded);
