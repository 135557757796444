import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import {
  Grid,
  Button,
  Icon,
  IconButton,
  Input,
  Tooltip
} from '@material-ui/core';
import Keyboard from 'react-simple-keyboard';
import { Trans } from 'react-i18next';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import { isMobile, isTablet } from 'react-device-detect';

// import DDlIdioma from '../../components/Idioma/dropDownListIdioma';
import 'react-simple-keyboard/build/css/index.css';
import image from '../../assets/images/Ticket.png';
import plate from '../../assets/imgs/plate.png';
import { logError } from '../../helpers/logger';
import { getTotemData } from '../../config/kiosk';
import './index.scss';

const LARGO_MIN_PATENTE = 5;
const LARGO_MAX_PATENTE = 7;

class CarFinder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputPatent: '',
      layoutName: 'default',
      disabledButton: true,
      keyboarDisable: 'none',
      inputPattern: /^[A-Za-z0-9\n]*$/,
      buscadorAbierto: false,
      teclaSel: false
    };

    window.addEventListener('resize', this.updatedWindowSize);
    this.sendPatent = this.sendPatent.bind(this);
  }

  componentDidMount() {
    this.updatedWindowSize();

    this.setState({
      keyboarDisable: isMobile ? 'text' : 'none'
    });
  }

  static defaultProps = {
    dispatch: null
  };

  static propTypes = {
    dispatch: PropTypes.func
  };

  updatedWindowSize = () => {
    if (window.innerWidth <= 570) {
      this.setState({
        keyboarDisable: 'text'
      });
    }
  };

  handleAbrirTeclado = () => {
    this.setState({
      buscadorAbierto: true
    });
  };

  handleCerrarTeclado = () => {
    const { inputPatent, teclaSel } = this.state;

    if (inputPatent === '' && teclaSel === false) {
      this.setState({
        buscadorAbierto: false
      });
    }

    this.setState({
      teclaSel: false
    });
  };

  resetForm = () => {};

  handleChange = event => {
    if (event.target === undefined) {
      this.setState({ inputPatent: event.toUpperCase() });

      if (event.length > 4) {
        this.setState({ disabledButton: false });
      } else {
        this.setState({ disabledButton: true });
      }
    } else {
      const re = /^[A-Za-z0-9\n]*$/;

      if (event.target.value === '' || re.test(event.target.value)) {
        this.setState({ inputPatent: event.target.value.toUpperCase() });

        if (
          event.target.name === 'InputPatent' &&
          event.target.value.length > 4
        ) {
          this.setState({ disabledButton: false });
        } else {
          this.setState({ disabledButton: true });
        }
      }
    }
  };

  handleClickOpen = element => {
    this.setState({ [element]: true });
  };

  handleClickFinder = () => {
    const { inputPatent } = this.state;

    this.sendPatent(inputPatent, false);
  };

  sendPatent = (inputPatent, isButtonEnter) => {
    if (inputPatent === undefined || inputPatent.length < LARGO_MIN_PATENTE) {
      return undefined;
    }

    ReactGA.event({
      category: 'find_your_car',
      action: isButtonEnter
        ? 'buscar_patente_boton_lupa'
        : 'buscar_patente_teclado_enter',
      label: `patente=${inputPatent}; Arauco+;`
    });
    ReactGA4.event({
      category: 'find_your_car',
      action: isButtonEnter
        ? 'buscar_patente_boton_lupa'
        : 'buscar_patente_teclado_enter',
      label: `patente=${inputPatent}; Arauco+;`
    });

    const { dispatch } = this.props;

    // dispatch(push(`${getTotemData(true).deviceName}/Carfinder/Results/${inputPatent}`));
    dispatch(push(`Carfinder/Results/${inputPatent}`));

    return undefined;
  };

  onChange = input => {
    this.setState({
      inputPatent: input
    });
  };

  onKeyPress = button => {
    const { inputPatent } = this.state;

    if (button === '{enter}') {
      document.getElementById('btnLupa').click();
    }

    if (button === '{space}') {
      this.setState({
        teclaSel: true
      });
    }

    if (inputPatent.length >= LARGO_MIN_PATENTE && button === '{enter}') {
      this.sendPatent(inputPatent, true);
    }
  };

  render() {
    try {
      const {
        inputPatent,
        inputPattern,
        layoutName,
        keyboarDisable,
        disabledButton,
        buscadorAbierto
      } = this.state;

      const origin = getTotemData(true).deviceName;

      return (
        <div>
          <div className='header-car altura bg-VERDE'>
            <div className='bar bg-VERDE-2'>
              <Link className='btn-volver' to={`/${origin}/Index`}>
                <Icon className='m-r'>arrow_back</Icon>
                <div>
                  <Trans i18nKey='busqueda_estacionamiento_resultado_btn_volver' />
                </div>
              </Link>
              {/* <DDlIdioma/> */}
            </div>
            <div className='bar-title'>
              <h1>
                <div className='fyc-imagen-patente'>
                  <img src={plate} alt='patente' />
                </div>
                <div>
                  {isTablet ? (
                    <Trans i18nKey='busqueda_patente_header_tablet' />
                  ) : (
                    <Trans i18nKey='busqueda_patente_header' />
                  )}
                </div>
              </h1>
            </div>
            <div className='wrap-input inicio_FYC_input'>
              <div className='wrap-inpu-inner'>
                <Input
                  type='text'
                  placeholder='ABCD12'
                  fullWidth
                  autoComplete='off'
                  name='InputPatent'
                  id='InputPatent'
                  value={inputPatent}
                  spellCheck={false}
                  inputProps={{
                    maxLength: LARGO_MAX_PATENTE,
                    inputMode: keyboarDisable
                  }}
                  onChange={e => this.handleChange(e)}
                  onFocus={this.handleAbrirTeclado}
                  onBlur={this.handleCerrarTeclado}
                  className='input-patent'
                />
                {!isMobile ? (
                  <Tooltip
                    title={<Trans i18nKey='busqueda_patente_tooltip_lupa' />}
                    placement='top'
                    open={!disabledButton}
                    arrow
                  >
                    <IconButton
                      className={
                        !disabledButton
                          ? 'btn-lupa btn-animacion-ripple'
                          : 'btn-lupa'
                      }
                      id='btnLupa'
                      disabled={disabledButton}
                      onClick={this.handleClickFinder}
                    >
                      <Icon>search</Icon>
                    </IconButton>
                  </Tooltip>
                ) : (
                  <IconButton
                    className={
                      !disabledButton
                        ? 'btn-lupa btn-animacion-ripple'
                        : 'btn-lupa'
                    }
                    id='btnLupa'
                    disabled={disabledButton}
                    onClick={this.handleClickFinder}
                  >
                    <Icon className='icon-search'>search</Icon>
                  </IconButton>
                )}
              </div>
            </div>
          </div>
          <div className='contenedor-normal'>
            <div className='text-center m-b-lg contenedor-body-fyc'>
              <h2 className='text-footer'>
                <Trans i18nKey='busqueda_patente_footer' />
              </h2>
              <Link
                to={`/${
                  getTotemData(true).deviceName
                }/Carfinder/BusquedaEstacionamiento`}
              >
                <Button
                  id='btnEstacionamientos'
                  variant='outlined'
                  fullWidth
                  color='primary'
                  className='btn-estacionamiento-buscar max-w-800'
                >
                  <Icon className='m-r arrow-back-btn-fyc'>arrow_back</Icon>
                  <Trans i18nKey='busqueda_patente_busqueda_button' />
                </Button>
              </Link>
            </div>
            <div className='adorno-separador' />
            <Grid container spacing={0} justifyContent='center'>
              <Grid item xs={4}>
                <div className='img-ticket text-center'>
                  <img src={image} alt='img' />
                </div>
              </Grid>
              <Grid item xs={8} className='text-center wrap-text-patente'>
                <h2>
                  <Trans i18nKey='busqueda_patente_no_recuerdas' />
                </h2>
                <p>
                  {isTablet ? (
                    <Trans i18nKey='busqueda_patente_no_recuerdas_descripcion_tablet' />
                  ) : (
                    <Trans i18nKey='busqueda_patente_no_recuerdas_descripcion' />
                  )}
                </p>
              </Grid>
            </Grid>
          </div>
          <div id='bg-footer' />
          {buscadorAbierto && (
            <div className='keyboardContainer'>
              <Keyboard
                keyboardRef={r => {
                  this.keyboard = r;
                }}
                layoutName={layoutName}
                id='InputPatent'
                value={inputPatent}
                maxLength={LARGO_MAX_PATENTE}
                onChange={this.handleChange}
                onFocus={() => Keyboard.dismiss()}
                onKeyPress={button => this.onKeyPress(button)}
                inputPattern={inputPattern}
                layout={{
                  default: [
                    '1 2 3 4 5 6 7 8 9 0',
                    'Q W E R T Y U I O P',
                    'A S D F G H J K L Ñ',
                    ' Z X C V B N M {bksp}',
                    ' {space} {enter}'
                  ]
                }}
              />
            </div>
          )}
        </div>
      );
    } catch (err) {
      logError('[CarFinder].[render]', {
        error: err,
        path: 'src/containers/CarFinder/index.jsx'
      });

      return undefined;
    }
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

export default connect(null, mapDispatchToProps)(CarFinder);
