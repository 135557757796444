import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Backdrop } from '@material-ui/core';

import { getImgModalUp } from '../../../../utils/generalFunction';

function ModalTransitionFloor({
  setOpen,
  onHandleClose,
  moverId,
  messageModal
}) {
  return (
    <Modal
      open={setOpen}
      onClose={onHandleClose}
      aria-labelledby='transition-modal-title'
      BackdropComponent={Backdrop}
      closeAfterTransition
      disableBackdropClick
      aria-describedby='transition-modal-description'
    >
      {getImgModalUp(moverId, messageModal)}
    </Modal>
  );
}

ModalTransitionFloor.defaultProps = {
  onHandleClose: undefined
};

ModalTransitionFloor.propTypes = {
  setOpen: PropTypes.bool.isRequired,
  moverId: PropTypes.number.isRequired,
  messageModal: PropTypes.bool.isRequired,
  onHandleClose: PropTypes.func
};

export default ModalTransitionFloor;
