import config from '../config/api';

import api from './api';

const DEFAULT_LANGUAGE = 'es';

export const getPoisApi = (mallId, type, states) =>
  api.post(
    `${config.baseApiUrl}/pois/poisByType`,
    {
      poiTypeId: type,
      poiStateIdIn: states,
      include: ['logo']
    },
    {
      headers: {
        'A-Mall-Id': mallId
      }
    }
  );

export const getStoresPoisApi = (mallId, languageId, states) =>
  api.post(
    `${config.baseApiUrl}/pois/storesPois`,
    {
      poiStateIdIn: states,
      languages: [{ id: languageId }],
      include: ['logo']
    },
    {
      headers: {
        'A-Mall-Id': mallId
      }
    }
  );

export const getGastronomyPoisApi = (mallId, languageId, states) =>
  api.post(
    `${config.baseApiUrl}/pois/gastronomyPois`,
    {
      poiStateIdIn: states,
      languages: [{ id: languageId }],
      include: ['logo']
    },
    {
      headers: {
        'A-Mall-Id': mallId
      }
    }
  );

export const getServicesPoisApi = (mallId, languageId, states) =>
  api.post(
    `${config.baseApiUrl}/pois/servicesPois`,
    {
      poiStateIdIn: states,
      languages: [{ id: languageId }],
      include: ['logo']
    },
    {
      headers: {
        'A-Mall-Id': mallId
      }
    }
  );

export const getServicesPoisByCategoryApi = (categoryId, mallId) =>
  api.post(
    `${config.baseApiUrl}/pois/servicesPois`,
    {
      categoryId,
      active: true,
      include: ['logo']
    },
    {
      headers: {
        'A-Mall-Id': mallId
      }
    }
  );

export const getPoi = (mallId, poiId, language) =>
  api.get(`${config.baseApiUrl}/pois/${poiId}`, {
    headers: {
      'a-mall-id': mallId,
      'Accept-Language': language
    }
  });

export const getCategory = (mallId, categoryId, language) =>
  api.get(`${config.baseApiUrl}/categories/${categoryId}`, {
    headers: {
      'a-mall-id': mallId,
      'Accept-Language': language
    }
  });

export const getCategoriesApi = (mallId, type) =>
  api.post(
    `${config.baseApiUrl}/categories/findByType`,
    {
      poiTypeId: type
    },
    {
      headers: {
        'a-mall-id': mallId
      }
    }
  );

export const getStoresCategoriesApi = (mallId, language) =>
  api.post(
    `${config.baseApiUrl}/categories/findByStore`,
    {},
    {
      headers: {
        'a-mall-id': mallId,
        'Accept-Language': language
      }
    }
  );

export const getGastronomyCategoriesApi = (mallId, language) =>
  api.post(
    `${config.baseApiUrl}/categories/findByGastronomy`,
    {},
    {
      headers: {
        'a-mall-id': mallId,
        'Accept-Language': language
      }
    }
  );

export const getServiceCategoriesApi = (mallId, language) =>
  api.post(
    `${config.baseApiUrl}/categories/findByService`,
    {},
    {
      headers: {
        'a-mall-id': mallId,
        'Accept-Language':
          language === DEFAULT_LANGUAGE ? language : DEFAULT_LANGUAGE
      }
    }
  );

export const getPoisApiCategory = (categoryId, mallId, states) =>
  api.post(
    `${config.baseApiUrl}/pois/storesByCategory`,
    {
      categoryId,
      poiStateIdIn: states,
      active: true,
      include: ['logo']
    },
    {
      headers: {
        'A-Mall-Id': mallId,
        'Accept-Language': DEFAULT_LANGUAGE
      }
    }
  );
