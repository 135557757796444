const COLORS = {
  error: '#E1211B',
  white: '#FFFFFF',
  darkGreen: '#3CAD07',
  lightGreen: '#44C606',
  grey: '#707070',
  textGrey: '#939393',
  lightGrey: '#E8E8E8`',
  black: '#000000'
};

export { COLORS };
