import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import genericStyles from '../styles/generic';

class StatusBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static propTypes = {
    children: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired
  };

  render() {
    const { children, backgroundColor } = this.props;

    return (
      <section style={{ ...genericStyles.statusBar, backgroundColor }}>
        {children}
      </section>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

export default connect(mapDispatchToProps)(StatusBar);
