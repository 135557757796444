import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Modal,
  Backdrop,
  Fade,
  Button,
  Icon,
  CircularProgress
} from '@material-ui/core';
import { push } from 'connected-react-router';
import { isTablet } from 'react-device-detect';
import { Trans } from 'react-i18next';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';

import styles from '../../styles/findCar';
import generalStyles from '../../styles/generic';
import NotResultCar from '../../assets/imgs/no_result_car.png';
import NotConnection from '../../assets/imgs/no_connection.png';
import NotFound from '../../assets/imgs/notfound.png';
import CameraIcon from '../../assets/imgs/camara.png';
import CloseIcon from '../../assets/imgs/close_icon.png';
import { getTotemData } from '../../config/kiosk';
import {
  getImages,
  resetImages,
  getImageDetails
} from '../../actions/findYourCar';
import api from '../../config/api';
import { logError } from '../../helpers/logger';
import './results.scss';

class Results extends React.Component {
  /* eslint-disable */
  constructor(props) {
    super(props);

    const { match } = this.props;

    this.state = {
      cajero: match.params.cajero,
      open: true,
      patent: '',
      fotos: null,
      flag: true,
      firstLoad: true
    };
  }

  componentDidMount = () => {
    const { dispatch, match, history } = this.props;
    const { patent, cajero } = match.params;

    if (cajero) {
      const url = window.location.href;
      const queryParams = new URLSearchParams(url);

      queryParams.delete('true');
      history.replace({
        pathname: `/${
          getTotemData(true).deviceName
        }/Carfinder/Results/${patent}`
      });
    }

    if (patent) {
      dispatch(getImages(patent));
      this.setState({ patent });
    }

    this.setState({ firstLoad: false });
  };

  static defaultProps = {
    dispatch: null,
    fetchingLicense: false,
    fetchingEndLicense: false,
    error: false,
    match: null,
    patent: '',
    dataImages: {},
    history: PropTypes.func
  };

  static propTypes = {
    dispatch: PropTypes.func,
    fetchingEndLicense: PropTypes.bool,
    fetchingLicense: PropTypes.bool,
    error: PropTypes.bool,
    match: PropTypes.object,
    patent: PropTypes.string,
    dataImages: PropTypes.object,
    history: PropTypes.func
  };

  componentDidUpdate = () => {
    if (this.state.flag) {
      this.handleGetPhoto();
    }
  };

  handleOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  handleBack = () => {
    const { dispatch } = this.props;
    const { cajero } = this.state;

    dispatch(resetImages());

    if (!cajero) {
      dispatch(push(`/${getTotemData(true).deviceName}/BusquedaPorPatente`));
    } else {
      dispatch(
        push(`/${getTotemData(true).deviceName}/BusquedaPorPatenteCajero`)
      );
    }
  };

  handleMissCar = () => {
    const { dispatch } = this.props;

    dispatch(
      push(
        `/${getTotemData(true).deviceName}/Carfinder/BusquedaEstacionamiento`
      )
    );
  };

  handleGoToParking = () => {
    const { dispatch, patent } = this.props;
    const { cajero } = this.state;

    dispatch(resetImages());

    ReactGA.event({
      category: 'find_your_car',
      action: 'auto_no_encontrado_no_es_ninguno_de_estos',
      label: `patente=${patent}; Arauco+;`
    });
    ReactGA4.event({
      category: 'find_your_car',
      action: 'auto_no_encontrado_no_es_ninguno_de_estos',
      label: `patente=${patent}; Arauco+;`
    });

    if (cajero) {
      dispatch(push(`/${getTotemData(true).deviceName}/atm-route`));
    } else {
      dispatch(
        push(
          `/${getTotemData(true).deviceName}/Carfinder/BusquedaEstacionamiento`
        )
      );
    }
  };

  handlePhoto = async (spotStayId, token) => {
    const mallId = localStorage.getItem('com.parauco.kiosk.mallId');

    const c = await fetch(
      `${api.baseApiUrlArauco}/v1/parking/image?spotStayId=${spotStayId}`,
      {
        method: 'GET',

        headers: {
          'Mall-Id': mallId,
          Token: token
        }
      }
    );

    const b = await c.json();
    if (b) {
      return b;
    }

    return {};
  };

  handleGetPhoto = async () => {
    const { dataImages } = this.props;

    if (dataImages.spots) {
      const a = dataImages.spots.map(
        async i => await this.handlePhoto(i.spotStayId, dataImages.token)
      );

      this.setState({ flag: false });

      const t = Promise.all(a);

      await t.then(r => this.setState({ fotos: r }));

      return;
    }

    return;
  };

  handleSelectPhoto = index => {
    const { dispatch, dataImages } = this.props;
    const { cajero } = this.state;
    const spot = dataImages.spots[index];
    const token = dataImages.token;

    dispatch(getImageDetails(spot, token, cajero));
  };

  render() {
    try {
      const { open, patent, cajero, fotos, firstLoad } = this.state;
      const { dataImages, fetchingLicense, error } = this.props;

      if (fetchingLicense || firstLoad) {
        return (
          <div className='wrap-list-fetching loading-map-wrapper '>
            <div className='loading-icon'>
              <CircularProgress />
            </div>
          </div>
        );
      }

      return (
        <React.Fragment>
          <div
            className={`header-car header-car-result ${
              cajero ? 'bg-MENTA' : 'bg-VERDE'
            }`}
          >
            <div className={`bar ${cajero ? 'bg-MENTA-2' : 'bg-VERDE-2'}`}>
              <div onClickCapture={this.handleBack} className='btn-volver'>
                <Icon className='m-r'>arrow_back</Icon>
                <div>
                  <Trans i18nKey='busqueda_patente_btn_inicio' />
                </div>
              </div>
            </div>
            <div className='bar-title'>
              <h1>
                {isTablet ? (
                  <Trans i18nKey='busqueda_patente_header_tablet' />
                ) : (
                  <Trans i18nKey='busqueda_patente_header' />
                )}
              </h1>
            </div>
            <div className='wrap-input wrap-input-result'>
              <span style={styles.patentText}>{patent}</span>
              <Button
                style={styles.patentButton}
                onClick={this.handleBack}
                className='btn-close'
              >
                <img
                  src={CloseIcon}
                  alt='Back'
                  style={styles.patentButtonImg}
                />
              </Button>
            </div>
          </div>

          <section style={styles.results} className='result-search'>
            {fotos && fotos.length > 0 && !error && (
              <div>
                {dataImages.spots[0].license === patent ? (
                  <span style={styles.selectCarText} className='title-result'>
                    <Trans i18nKey='busqueda_patente_resultado_txt_auxiliar_short' />
                  </span>
                ) : (
                  <span style={styles.selectCarText} className='title-result'>
                    <Trans i18nKey='busqueda_patente_resultado_txt_auxiliar' />
                  </span>
                )}
                <div
                  className='resultado-imagenes'
                  style={styles.resultsContainer}
                >
                  {fotos?.map((i, index) => {
                    if (index < 4) {
                      const imagePath = `data:image/png;base64,${i.fileBase64}`;

                      return (
                        <div className=''>
                          <Button
                            style={styles.resultsImgContainer}
                            key={i.id}
                            onClick={() =>
                              !fetchingLicense
                                ? this.handleSelectPhoto(index)
                                : null
                            }
                          >
                            <article>
                              <img
                                style={styles.resultsImg}
                                src={imagePath}
                                onError={e => {
                                  e.target.onerror = null;
                                  e.target.src = NotFound;
                                }}
                                alt='parking_path'
                              />
                            </article>
                            <div
                              className={`btn-imagen-auto ${
                                cajero ? 'bg-MENTA' : 'bg-VERDE'
                              }`}
                            >
                              <Trans i18nKey='busqueda_patente_resultado_boton_ver' />
                            </div>
                          </Button>
                        </div>
                      );
                    }

                    return <div key={i.id} />;
                  })}
                </div>
                {fotos.length > 0 && (
                  <Modal
                    aria-labelledby='transition-modal-title'
                    aria-describedby='transition-modal-description'
                    open={open}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500
                    }}
                  >
                    <Fade in={open}>
                      <div style={styles.modal} className='modal-result-search'>
                        <img
                          style={styles.modalIcon}
                          src={CameraIcon}
                          alt='camara_icon'
                          className='icon-camara'
                        />
                        <h1 style={styles.modalTitle}>
                          <Trans i18nKey='busqueda_patente_resultado_titulo_modal' />
                        </h1>
                        <div
                          style={styles.modalDescription}
                          className='text-modal'
                        >
                          <Trans i18nKey='busqueda_patente_resultado_cuerpo_modal' />
                        </div>
                        <Button
                          onClick={this.handleClose}
                          style={styles.modalButton}
                          className={`btn-modal ${
                            cajero ? 'bg-MENTA' : 'bg-VERDE'
                          }`}
                        >
                          <span style={styles.backText}>
                            <Trans i18nKey='busqueda_patente_resultado_boton_modal' />
                          </span>
                        </Button>
                      </div>
                    </Fade>
                  </Modal>
                )}
              </div>
            )}
            {fotos && fotos.length === 0 && !error && (
              <div className='margin_bottom_FYC'>
                <h1 style={generalStyles.error}>
                  <Trans i18nKey='busqueda_patente_msj_titulo_not_found' />
                </h1>
                <div style={styles.notFoundMessageContainer}>
                  <p style={styles.notFoundMessage}>
                    <Trans i18nKey='busqueda_patente_msj_cuerpo_not_found' />
                  </p>
                </div>
                <img
                  style={styles.notFoundImage}
                  src={NotResultCar}
                  alt='no_result'
                  className='img-no-result'
                />
              </div>
            )}
            {error && (
              <div className='margin_bottom_FYC'>
                <h1 style={generalStyles.error}>
                  <Trans i18nKey='busqueda_patente_msj_titulo_error' />
                </h1>
                <div style={styles.notFoundMessageContainer}>
                  <p style={styles.notFoundMessage}>
                    <Trans i18nKey='busqueda_patente_msj_cuerpo_error' />
                  </p>
                </div>
                <img
                  style={styles.notConnectionImage}
                  src={NotConnection}
                  alt='no_result'
                  className='img-not-connection'
                />
              </div>
            )}
          </section>

          <div
            className={
              error ? 'container_search_floor short' : 'container_search_floor'
            }
          >
            <div className='col_search_floor size_col_search_floor'>
              {dataImages && dataImages.data && dataImages.data.length === 0 ? (
                <Trans i18nKey='busqueda_patente_auto' />
              ) : (
                <Trans i18nKey='busqueda_patente_autoMultiples' />
              )}
            </div>
            <div className='col_search_floor'>
              <button
                className='button_search_floor'
                onClick={this.handleMissCar}
              >
                <Trans i18nKey='busqueda_patente_search_button' />
              </button>
            </div>
          </div>
        </React.Fragment>
      );
    } catch (err) {
      logError('[Results].[render]', {
        error: err,
        path: 'src/containers/CarFinder/results.jsx'
      });

      return undefined;
    }
  }
}

const mapStateToProps = state => ({
  fetchingLicense: state.findYourCar.status.fetchingLicense,
  fetchingEndLicense: state.findYourCar.status.fetchingEndLicense,
  error: state.app.status.error,
  patent: state.findYourCar.data.patent,
  dataImages: state.findYourCar.data.parkingImages
});

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Results);
