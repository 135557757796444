import React from 'react';
import { connect } from 'react-redux';
import { Grid, Icon } from '@material-ui/core/';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';

import imgMapa from '../../assets/images/Mapa.png';
import imgGallo from '../../assets/images/animales/Gallo.png';
import imgElefante from '../../assets/images/animales/Elefante.png';
import imgCerdo from '../../assets/images/animales/Cerdo.png';
import imgConejo from '../../assets/images/animales/Conejo.png';
import imgPerro from '../../assets/images/animales/Perro.png';
import imgPato from '../../assets/images/animales/Pato.png';
import imgJirafa from '../../assets/images/animales/Jirafa.png';
import imgCaballo from '../../assets/images/animales/Caballo.png';
import imgMariposa from '../../assets/images/animales/Mariposa.png';
import imgPiña from '../../assets/images/frutas/piña.png';
import imgManzana from '../../assets/images/frutas/manzana.png';
import imgNaranja from '../../assets/images/frutas/naranja.png';
import imgUva from '../../assets/images/frutas/uva.png';
import imgPera from '../../assets/images/frutas/pera.png';
import imgLimon from '../../assets/images/frutas/limon.png';
import imgArandano from '../../assets/images/frutas/arandanos.png';
import imgSandia from '../../assets/images/frutas/sandia.png';
// import DDlIdioma from '../../components/Idioma/dropDownListIdioma';
import { logError } from '../../helpers/logger';
import { selectPoi } from '../../actions/lists';
import { getTotemData } from '../../config/kiosk';

import './generales.scss';
import './busquedaEstacionamiento.scss';

class BusquedaEstacionamiento extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static defaultProps = {
    location: null,
    dispatch: PropTypes.any
  };

  static propTypes = {
    location: PropTypes.any,
    dispatch: PropTypes.any
  };

  handleGoToStore = (floor, url, secondaryName) => {
    const { dispatch, location } = this.props;
    const origin = getTotemData(true).deviceName;
    let cash = '';

    if (location.state) {
      cash = location.state.stateCash;
    }

    const item = {
      id: '794',
      name: cash
        ? 'Cajeros Estacionamientos Efectivo'
        : 'Cajeros Estacionamientos',
      icon: 'icon-Bancos',
      isSelectable: false,
      mallId: 1,
      poiTypeId: 5,
      slug: null,
      storeCount: 1,
      urlLanding: null,
      bgColorClass: 'bg-MINT',
      originPath: '/index',
      destinPath: '/ResultadoMapa',
      mapTitle: 'RUTA-CAJERO',
      suc: floor,
      noShowPath: true,
      atm: true,
      secondaryName
    };

    dispatch(selectPoi(item));

    if (location.pathname === `/${origin}/atm-route`) {
      dispatch(push(`/${origin}/ResultadoMapa`));
    }

    if (location.pathname !== `/${origin}/atm-route`) {
      dispatch(push(`/${origin}${url}`));
    }
  };

  render() {
    const { location } = this.props;
    const origin = getTotemData(true).deviceName;

    try {
      return (
        <div>
          <div
            className={
              location.pathname === `/${origin}/atm-route`
                ? 'cabecera bg-MENTA cabecera-busqueda-estacionamiento'
                : 'cabecera bg-VERDE cabecera-busqueda-estacionamiento'
            }
          >
            <div
              className={
                location.pathname === `/${origin}/atm-route`
                  ? 'barra bg-MENTA-2'
                  : 'barra bg-VERDE-2'
              }
            >
              <Link
                className='btn-volver'
                to={
                  location.pathname === `/${origin}/atm-route`
                    ? `/${origin}`
                    : `/${origin}/BusquedaPorPatente`
                }
              >
                <Icon className='m-r'>arrow_back</Icon>
                <div>
                  <Trans i18nKey='busqueda_patente_encontrado_volver' />
                </div>
              </Link>
              {/* <DDlIdioma /> */}
            </div>
            <div className='barra-titulo'>
              <h1>
                <Trans
                  i18nKey={
                    isMobile && !isTablet
                      ? 'busqueda_estacionamiento_header_mobile'
                      : 'busqueda_estacionamiento_header'
                  }
                />
              </h1>
            </div>
          </div>
          <div className='contenedor-mapa'>
            <img alt='' src={imgMapa} className='img-mapa' />
          </div>
          <div className='contenedor-listas'>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <h2>
                  <Trans i18nKey='busqueda_estacionamiento_zona_paris_ripley' />
                </h2>
              </Grid>
              <Grid item xs={6}>
                <h2>
                  <Trans i18nKey='busqueda_estacionamiento_zona_blvd_falabella' />
                </h2>
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <div
                  onClick={() =>
                    this.handleGoToStore(
                      'E3A',
                      '/Carfinder/ResultadoEsta:selGallo'
                    )
                  }
                  className='btn-base-animal c-white'
                >
                  <div className='numero izquierda'>6</div>
                  <div className='derecha'>
                    <img alt='' src={imgGallo} className='img-animal' />
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className='btn-base-animal c-disabled' />
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <div
                  onClick={() =>
                    this.handleGoToStore(
                      'E5A',
                      '/Carfinder/ResultadoEsta:selConejo'
                    )
                  }
                  className='btn-base-animal c-CAFE'
                >
                  <div className='numero izquierda'>5</div>
                  <div className='derecha'>
                    <img alt='' src={imgConejo} className='img-animal' />
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className='btn-base-animal c-disabled' />
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <div
                  onClick={() =>
                    this.handleGoToStore(
                      'E4A',
                      '/Carfinder/ResultadoEsta:selElefante'
                    )
                  }
                  className='btn-base-animal c-AZUL'
                >
                  <div className='numero izquierda'>4</div>
                  <div className='derecha'>
                    <img alt='' src={imgElefante} className='img-animal' />
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className='btn-base-animal c-disabled' />
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <div
                  onClick={() =>
                    this.handleGoToStore(
                      'E3A',
                      '/Carfinder/ResultadoEsta:selCerdo'
                    )
                  }
                  className='btn-base-animal c-ROSADO'
                >
                  <div className='numero izquierda'>3</div>
                  <div className='derecha'>
                    <img alt='' src={imgCerdo} className='img-animal' />
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className='btn-base-animal c-disabled' />
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <div
                  onClick={() =>
                    this.handleGoToStore(
                      'E2A',
                      '/Carfinder/ResultadoEsta:selPerro'
                    )
                  }
                  className='btn-base-animal c-ROJO'
                >
                  <div className='numero izquierda'>2</div>
                  <div className='derecha'>
                    <img alt='' src={imgPerro} className='img-animal' />
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div
                  onClick={() =>
                    this.handleGoToStore(
                      'E2F',
                      '/Carfinder/ResultadoEsta:selPiña',
                      'Express'
                    )
                  }
                  className='btn-base-animal c-DORADO'
                >
                  <div className='numero izquierda'>
                    2 <span className='txt-largo'>Express</span>
                  </div>
                  <div className='derecha'>
                    <img alt='' src={imgPiña} className='img-animal' />
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <div
                  onClick={() =>
                    this.handleGoToStore(
                      'E1A',
                      '/Carfinder/ResultadoEsta:selPato'
                    )
                  }
                  className='btn-base-animal c-AMARILLO'
                >
                  <div className='numero izquierda'>1</div>
                  <div className='derecha'>
                    <img alt='' src={imgPato} className='img-animal' />
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div
                  onClick={() =>
                    this.handleGoToStore(
                      'E1F',
                      '/Carfinder/ResultadoEsta:selPera',
                      'Express / Andén'
                    )
                  }
                  className='btn-base-animal c-VERDE'
                >
                  <div className='numero izquierda'>
                    1 <span className='txt-largo'>Express / Andén</span>
                  </div>
                  <div className='derecha'>
                    <img alt='' src={imgPera} className='img-animal' />
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <div
                  onClick={() =>
                    this.handleGoToStore(
                      'E-1A',
                      '/Carfinder/ResultadoEsta:selJirafa'
                    )
                  }
                  className='btn-base-animal c-NARANJO'
                >
                  <div className='numero izquierda'>-1</div>
                  <div className='derecha'>
                    <img alt='' src={imgJirafa} className='img-animal' />
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div
                  onClick={() =>
                    this.handleGoToStore(
                      'E-1F',
                      '/Carfinder/ResultadoEsta:selManzana'
                    )
                  }
                  className='btn-base-animal c-ROJO'
                >
                  <div className='numero izquierda'>-1</div>
                  <div className='derecha'>
                    <img alt='' src={imgManzana} className='img-animal' />
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <div
                  onClick={() =>
                    this.handleGoToStore(
                      'E-2A',
                      '/Carfinder/ResultadoEsta:selCaballo'
                    )
                  }
                  className='btn-base-animal c-VERDE'
                >
                  <div className='numero izquierda'>-2</div>
                  <div className='derecha'>
                    <img alt='' src={imgCaballo} className='img-animal' />
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div
                  onClick={() =>
                    this.handleGoToStore(
                      'E-2F',
                      '/Carfinder/ResultadoEsta:selUva'
                    )
                  }
                  className='btn-base-animal c-MORADO'
                >
                  <div className='numero izquierda'>-2</div>
                  <div className='derecha'>
                    <img alt='' src={imgUva} className='img-animal' />
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <div
                  onClick={() =>
                    this.handleGoToStore(
                      'E-3A',
                      '/Carfinder/ResultadoEsta:selMariposa'
                    )
                  }
                  className='btn-base-animal c-CELESTE'
                >
                  <div className='numero izquierda'>-3</div>
                  <div className='derecha'>
                    <img alt='' src={imgMariposa} className='img-animal' />
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div
                  onClick={() =>
                    this.handleGoToStore(
                      'E-3F',
                      '/Carfinder/ResultadoEsta:selLimon'
                    )
                  }
                  className='btn-base-animal c-AMARILLO'
                >
                  <div className='numero izquierda'>-3</div>
                  <div className='derecha'>
                    <img alt='' src={imgLimon} className='img-animal' />
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <div className='btn-base-animal c-disabled' />
              </Grid>
              <Grid item xs={6}>
                <div
                  onClick={() =>
                    this.handleGoToStore(
                      'E-4F',
                      '/Carfinder/ResultadoEsta:selSandia'
                    )
                  }
                  className='btn-base-animal c-ROSADO'
                >
                  <div className='numero izquierda'>-4</div>
                  <div className='derecha'>
                    <img alt='' src={imgSandia} className='img-animal' />
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <div className='btn-base-animal c-disabled' />
              </Grid>
              <Grid item xs={6}>
                <div
                  onClick={() =>
                    this.handleGoToStore(
                      'E-5F',
                      '/Carfinder/ResultadoEsta:selNaranja'
                    )
                  }
                  className='btn-base-animal c-NARANJO'
                >
                  <div className='numero izquierda'>-5</div>
                  <div className='derecha'>
                    <img alt='' src={imgNaranja} className='img-animal' />
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <div className='btn-base-animal c-disabled' />
              </Grid>
              <Grid item xs={6}>
                <div
                  onClick={() =>
                    this.handleGoToStore(
                      'E-6F',
                      '/Carfinder/ResultadoEsta:selArandano'
                    )
                  }
                  className='btn-base-animal c-AZUL'
                >
                  <div className='numero izquierda'>-6</div>
                  <div className='derecha'>
                    <img alt='' src={imgArandano} className='img-animal' />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      );
    } catch (err) {
      logError('[BusquedaEstacionamiento].[render]', {
        error: err,
        path: 'src/containers/CarFinder/busquedaEstacionamiento.jsx'
      });

      return undefined;
    }
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

export default connect(mapDispatchToProps)(BusquedaEstacionamiento);
