import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import { Icon, CircularProgress } from '@material-ui/core/';
import { Link } from 'react-router-dom';

import { getTotemData, getMall, getDevice } from '../../config/kiosk';
// import DDlIdioma from '../../components/Idioma/dropDownListIdioma';
import InputBuscadorDirectorio from '../../components/Buscador/InputBuscadorDirectorio';
import Lista from '../../components/Lista/List';
import { getCategoriesData, selecOrigen } from '../../actions/lists';
import generalStyles from '../../styles/generic';
import Error from '../../components/Error/Error';
import NoResults from '../../components/Buscador/NoResults';
import { DIRECTORY } from '../../constants/directories';
import { POI_TYPE } from '../../constants/poiType';

import './Main.scss';

class MainServicios extends React.Component {
  constructor(props) {
    super(props);
    const { language } = this.props;

    this.state = {
      categories: [],
      filteredCategories: [],
      searchText: '',
      currentLanguage: language
    };
    this.timer = null;
  }

  static defaultProps = {
    dispatch: null,
    fetching: false,
    dataCategories: [],
    language: '',
    error: false
  };

  static propTypes = {
    dispatch: PropTypes.func,
    fetching: PropTypes.bool,
    dataCategories: PropTypes.array,
    mainSearchResult: PropTypes.object.isRequired,
    language: PropTypes.string,
    error: PropTypes.bool,
    match: PropTypes.any.isRequired
  };

  componentDidMount = () => {
    this.getCategories();
    const { match } = this.props;
    const { params } = match;
    const { origin } = params;

    getDevice(origin);
  };

  componentWillUnmount = () => {
    const { dispatch } = this.props;

    dispatch(selecOrigen(''));
  };

  componentDidUpdate(prevProps, prevState) {
    const { language } = this.props;
    const { currentLanguage } = prevState;

    if (currentLanguage !== language) {
      this.getCategories();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { dataCategories } = nextProps;
    const { filteredCategories, searchText } = prevState;

    const newCategories = dataCategories !== null ? dataCategories[0] : [];

    const newFilteredCategories =
      filteredCategories && searchText !== ''
        ? filteredCategories
        : newCategories;

    return {
      categories: newCategories,
      filteredCategories: newFilteredCategories
    };
  }

  getCategories = () => {
    const { dispatch, language } = this.props;

    const mallId = getMall();

    ReactGA.event({
      category: 'servicios',
      action: 'listado_servicios_boton_menu',
      label: `mallId=${mallId};`
    });
    ReactGA4.event('listado_servicios_boton_menu', {
      category: 'servicios',
      label: `mallId=${mallId};`
    });

    dispatch(getCategoriesData(mallId, POI_TYPE.SERVICES, language));
    this.setState({ currentLanguage: language });
  };

  handleInputChange = event => {
    const searchText = event.target.value;

    this.filterData(searchText);
  };

  handleKeyboardInputChange = event => {
    this.filterData(event);
  };

  cleanString = string =>
    string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  filterData = searchText => {
    const { categories } = this.state;

    window.scrollTo({ top: 0 });

    const newSearchText = this.cleanString(searchText);

    this.setState({ searchText: newSearchText });

    if (categories !== undefined && newSearchText !== undefined) {
      const filteredCategories = categories.filter(
        c =>
          this.cleanString(c.name)
            .toLowerCase()
            .indexOf(newSearchText.toLowerCase()) !== -1
      );

      this.setState({ filteredCategories });
    }

    if (newSearchText.length === 0) {
      this.setState({ filteredCategories: categories });
    }

    if (this.timer !== null) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      ReactGA.event({
        category: 'servicios',
        action: 'busqueda_teclado_servicios',
        label: `searchText=${searchText};`
      });
      ReactGA4.event('busqueda_teclado_servicios', {
        category: 'servicios',
        label: `searchText=${searchText};`
      });
    }, 2000);
  };

  render() {
    const { filteredCategories, searchText } = this.state;
    const { fetching, error, mainSearchResult } = this.props;

    return (
      <div className=''>
        <div className='cabecera bg-GRIS fnd-tiendas'>
          <div className='barra bg-GRIS-2'>
            <Link
              className='btn-volver'
              to={`/${getTotemData(true).deviceName}/Index`}
            >
              <Icon className='m-r'>arrow_back</Icon>
              <Trans i18nKey='busqueda_patente_btn_inicio' />
            </Link>
            {/* TO DO: por ahora se mantendra oculta la opcion de cambiar de idiomas ya que no estaba funcionando bien
             <div className='posicionar-idioma'>
              <DDlIdioma theme='dark' />
            </div>
            */}
          </div>
          <div className='contenido'>
            <div className='titulo-cabecera'>
              <Trans i18nKey='label_tipo_servicio' />
            </div>
            <div className='contenedor-input'>
              <InputBuscadorDirectorio
                directory={DIRECTORY.SERVICE}
                isCategory={false}
              />
            </div>
          </div>
        </div>
        <div className='wrap-lista-body'>
          {fetching && (
            <CircularProgress style={generalStyles.progressServices} />
          )}
          {error && (
            <Error
              message={<Trans i18nKey='busqueda_sin_resultados_servicio' />}
            />
          )}
          {!error && (
            <div>
              {filteredCategories && filteredCategories.length === 0 && (
                <div className='no-results-container'>
                  <NoResults
                    text={
                      <React.Fragment>
                        <Trans i18nKey='smart_search_no_service_results' />
                        {` "${searchText}" `}
                      </React.Fragment>
                    }
                  />
                </div>
              )}
              {mainSearchResult.inputText &&
              mainSearchResult.inputText.length > 2 ? null : (
                <Lista
                  showMoreInfo={false}
                  data={filteredCategories}
                  tipo={DIRECTORY.SERVICE}
                  modo='categorias'
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  fetching: state.lists.status.fetching,
  error: state.lists.status.error,
  dataCategories: state.lists.data.categories,
  mainSearchResult: state.search.data.mainSearchResult,
  language: state.app.data.language,
  selectOrigen: state.lists.data.selectedOrigen
});

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(MainServicios);
