import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';

import Main from './components/Main/view';
import { theme } from './theme/theme';
import configureStore, { history } from './rootStore';
import './styles/app.scss';
import './styles/fonts.css';

const store = configureStore();
// This is the main component
const App = () => (
  <Provider store={store}>
    <Router history={history}>
      <MuiThemeProvider theme={theme}>
        <Main />
      </MuiThemeProvider>
    </Router>
  </Provider>
);

export default App;
