import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

import { loadMapData } from '../../actions/map';

import CentreMap from './centre-map';

class Map extends React.PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    core: PropTypes.object,
    activeVenue: PropTypes.object,
    destinations: PropTypes.array,
    isLoadingMap: PropTypes.bool.isRequired,
    highlightedDestinations: PropTypes.array,
    onEndMapInstructions: PropTypes.func.isRequired,
    status: PropTypes.bool.isRequired,
    statusQrModal: PropTypes.bool.isRequired,
    idTotemSuc: PropTypes.string.isRequired
  };

  static defaultProps = {
    core: null,
    activeVenue: null,
    destinations: null,
    highlightedDestinations: null
  };

  componentDidMount = () => {
    const { dispatch } = this.props;

    dispatch(loadMapData());
  };

  render() {
    const {
      activeVenue,
      core,
      highlightedDestinations,
      destinations,
      isLoadingMap,
      onEndMapInstructions,
      status,
      statusQrModal,
      idTotemSuc
    } = this.props;

    return (
      <React.Fragment>
        {isLoadingMap && (
          <div className='loading-map-wrapper'>
            <div className='loading-icon'>
              <CircularProgress />
            </div>
          </div>
        )}
        <div className='mapBloquer' />
        <div id='map-holder-control'>
          <CentreMap
            activeVenue={activeVenue}
            core={core}
            highlightedDestinations={highlightedDestinations}
            destinations={destinations}
            onEndMapInstructions={onEndMapInstructions}
            statusModalBack={status}
            statusQrModalSecond={statusQrModal}
            sucTotem={idTotemSuc}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

const mapStateToProps = state => ({
  mapDevices: state.lists.data.mapDevices,
  activeVenue: state.app.data.activeVenue,
  isLoadingMap: state.app.status.isLoadingMap,
  core: state.app.data.core,
  highlightedDestinations: state.app.data.highlightedDestinations,
  destinations: state.app.data.destinations
});

export default connect(mapStateToProps, mapDispatchToProps)(Map);
