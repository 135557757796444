import { createLogger, format, transports } from 'winston';
import WinstonCloudWatch from 'winston-cloudwatch';

import { environment } from '../config/api';
import envVariables from '../config/envVariables';

// eslint-disable-next-line new-cap
const logger = new createLogger({
  format: format.json(),
  transports: [
    new transports.Console({
      timestamp: true,
      colorize: true
    })
  ]
});

if (envVariables.nodeEnv === 'production') {
  const cloudwatchConfig = {
    logGroupName: envVariables.logGroupName,
    logStreamName: `${envVariables.logGroupName}-${environment}`,
    awsAccessKeyId: envVariables.awsAccessKeyId,
    awsSecretKey: envVariables.awsSecretKey,
    awsRegion: envVariables.awsRegion,
    messageFormatter: log =>
      `{ "type": "${log.level}", "message": "${
        log.message
      }" \n"data": "${JSON.stringify(log.additionalInfo)}"}`
  };

  logger.add(new WinstonCloudWatch(cloudwatchConfig));
}

export const logError = (message, data) =>
  logger.log({ level: 'error', message, additionalInfo: data });

export const logInfo = (message, data) =>
  logger.log({ level: 'info', message, additionalInfo: data });
