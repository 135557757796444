import { createActions } from 'reduxsauce';
import { push } from 'connected-react-router';

import { getImagesApi, getImageDetailApi } from '../services/findYourCarApi';
import { getTotemData } from '../config/kiosk';

const { Types, Creators } = createActions(
  {
    setLastClickDate: ['date'],
    setParkingImages: ['data'],
    setData: ['key', 'value'],
    setStatus: ['key', 'value'],
    getImages: ['patent'],
    resetImages: [],
    setSelectedImage: ['data'],
    setPatent: ['data'],
    setFloorData: ['data'],
    setAllFloor: ['data'],
    getImageDetails: ['selectedImage', 'token']
  },
  {
    prefix: 'FIND_YOUR_CAR/'
  }
);

const {
  setParkingImages,
  setData,
  setLastClickDate,
  setStatus,
  setSelectedImage,
  setFloorData,
  setAllFloor,
  setPatent
} = Creators;

const {
  SET_PARKING_IMAGES,
  SET_DATA,
  SET_STATUS,
  SET_PATENT,
  SET_SELECTED_IMAGE,
  SET_LAST_CLICK_DATE,
  SET_FLOOR_DATA,
  SET_ALL_FLOOR
} = Types;

const resetImages = () =>
  function action(dispatch) {
    dispatch(setStatus('fetching', false));
    dispatch(setStatus('error', false));
    dispatch(setParkingImages({}));
  };

const getImages = patent => dispatch => {
  try {
    dispatch(setStatus('fetchingLicense', true));
    dispatch(setStatus('fetchingEndLicense', false));
    const request = getImagesApi(patent);

    request
      .then(response => {
        dispatch(setParkingImages(response.data));
        setTimeout(() => {
          dispatch(setStatus('fetchingEndLicense', true));
        }, 3000);
        setTimeout(() => {
          dispatch(setStatus('fetchingLicense', false));
          dispatch(setStatus('error', false));
        }, 3700);
      })
      .catch(() => {
        dispatch(setStatus('fetchingLicense', false));
        dispatch(setStatus('error', true));
        dispatch(setParkingImages({}));
      });
  } catch {
    dispatch(setStatus('fetchingLicense', false));
    dispatch(setStatus('error', true));
    dispatch(setParkingImages({}));
  }
};

const getImageDetails = (selectedImage, token, cajero) => async dispatch => {
  dispatch(setStatus('fetchingLicense', true));
  dispatch(setStatus('fetchingEndLicense', false));

  try {
    const request = await getImageDetailApi(selectedImage, token);

    if (request.status === 200) {
      // const dataFloorReq = await getFloorDataApi(
      //   String(request.data[0].externalParking.name).replace('+', '%2B')
      // );

      // if (dataFloorReq.status === 200) {
      // const floor = dataFloorReq.data.data[0];
      const floor = {
        suc: request.data[0].suc,
        backgroundColor: request.data[0].floor.backgroundColor,
        floor: request.data[0].floor.number,
        colorName: request.data[0].floor.colorText,
        // description: request.data[0].floor.name,
        description: request.data[0].floor.urlImage,
        exitName: request.data[0].parkingName,
        imageSrc: request.data[0].floor.urlImage,
        headerColor: request.data[0].floor.headerColor
      };
      const floorData = request.data[0];

      dispatch(setStatus('fetchingEndLicense', true));
      dispatch(setStatus('fetchingLicense', true));
      dispatch(setPatent(selectedImage.license));
      dispatch(setFloorData(floor));
      dispatch(setAllFloor(floorData));

      if (cajero) {
        dispatch(
          push(`/${getTotemData(true).deviceName}/CarFinder/finded/true`)
        );
      } else {
        dispatch(push(`/${getTotemData(true).deviceName}/CarFinder/finded`));
      }
      // }
    }
  } catch {
    dispatch(setStatus('fetchingLicense', false));
    dispatch(setStatus('error', true));
    dispatch(setParkingImages({}));
  }
};

export {
  Types,
  setParkingImages,
  setData,
  setStatus,
  SET_PARKING_IMAGES,
  SET_DATA,
  SET_STATUS,
  SET_PATENT,
  getImages,
  resetImages,
  setPatent,
  getImageDetails,
  SET_SELECTED_IMAGE,
  setSelectedImage,
  SET_FLOOR_DATA,
  SET_ALL_FLOOR,
  setFloorData,
  setLastClickDate,
  SET_LAST_CLICK_DATE
};

export default Creators;
