const METRICS = {
  display: {
    grid: 'Grid',
    block: 'block'
  },
  width: {
    patentContainer: '100%'
  },
  height: {
    patentSection: '21%',
    patentContainer: '200px',
    dudeFooter: '27%'
  }
};

export { METRICS };
