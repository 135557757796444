import { COLORS } from '../constants/colors';
import { METRICS } from '../constants/metrics';
import palette from '../theme/palette';

const styles = {
  link: {
    textDecoration: 'none',
    fontSize: 'inherit'
  },
  modal: {
    width: '80%',
    margin: '30% auto',
    borderRadius: '50px',
    textAlign: 'center',
    padding: '10% 5%',
    zIndex: 9,
    backgroundColor: COLORS.white
  },
  modalIcon: {
    width: '202px',
    height: '165px',
    display: METRICS.display.block,
    margin: 'auto'
  },
  modalTitle: {
    fontSize: '30px'
  },
  modalDescription: {
    fontSize: '20px'
  },
  modalButton: {
    backgroundColor: COLORS.lightGreen,
    width: '300px',
    borderRadius: '70px',
    marginTop: '60px',
    height: '70px'
  },
  backContainer: {
    padding: '2%'
  },
  backIcon: {
    width: '20%'
  },
  smallBackText: {
    color: COLORS.white,
    fontSize: '1.5em',
    textTransform: 'initial'
  },
  backText: {
    color: COLORS.white,
    fontSize: '2em',
    marginLeft: '15px',
    textTransform: 'initial'
  },
  patentSection: {
    display: METRICS.display.grid,
    height: METRICS.height.patentSection,
    backgroundColor: COLORS.lightGreen
  },
  patentTitle: {
    textAlign: 'center',
    width: '50%',
    fontWeight: 'normal',
    margin: '5% auto',
    fontSize: '3em',
    color: COLORS.white
  },
  patentContainer: {
    // width: METRICS.width.patentContainer,
    // height: METRICS.height.patentContainer,
    width: '100%',
    height: '100%',
    textAlign: 'center',
    borderRadius: '100px',
    margin: '10px auto',
    backgroundColor: COLORS.white,
    boxShadow: '2px 6px 15px 2px #00000029'
  },
  smallPatentContainer2: {
    width: '85%',
    height: '60px',
    textAlign: 'center',
    borderRadius: '100px',
    margin: '-20px auto 0 auto',
    backgroundColor: COLORS.white,
    boxShadow: '2px 6px 15px 2px #00000029'
  },
  patentContainer2: {
    width: '85%',
    height: '200px',
    textAlign: 'center',
    borderRadius: '100px',
    margin: '-150px auto 0 auto',
    backgroundColor: COLORS.white,
    boxShadow: '2px 6px 15px 2px #00000029'
  },
  smallPatentText: {
    fontSize: '2em',
    width: '100%',
    marginTop: '20px',
    textAlign: 'center',
    display: 'inline-grid',
    color: COLORS.black
  },
  patentText: {
    fontSize: '70px',
    width: '100%',
    marginTop: '27px',
    textAlign: 'center',
    display: 'inline-grid',
    color: COLORS.black
  },
  patentFind: {
    fontSize: '80px',
    marginTop: '35px',
    display: 'inline-grid'
  },
  smallPatentButton: {
    position: 'absolute',
    margin: '2% 0'
  },
  patentButton: {
    position: 'absolute',
    margin: '20px 20px'
  },
  patentButtonSearch: {
    position: 'absolute',
    marginTop: '40px'
  },
  smallPatentButtonImg: {
    width: '25px',
    height: '25px'
  },
  patentButtonImg: {
    width: '42px',
    height: '45px'
  },
  selectCarText: {
    marginTop: '12%',
    fontSize: '1.5rem',
    display: 'block'
  },
  results: {
    marginTop: '7%',
    textAlign: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    minHeight: '43%'
  },
  resultsContainer: {
    padding: '3% 1%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: 'auto'
  },
  resultsImgContainer: {
    display: 'block',
    width: '380px',
    height: '280px',
    padding: '15px',
    borderRadius: '15px',
    boxShadow: '2px 6px 15px 2px #00000029',
    margin: '30px'
  },
  resultsImg: {
    width: '250px',
    height: '150px'
  },
  notFoundMessageContainer: {
    display: 'inline-grid',
    width: '100%',
    justifyContent: 'center'
  },
  notFoundMessage: {
    width: '60%',
    margin: 'auto',
    fontWeight: 'normal'
  },
  notFoundImage: {
    width: '50%',
    marginTop: '100px',
    marginBottom: '60px'
  },
  notConnectionImage: {
    width: '100%',
    marginTop: '100px',
    marginBottom: '60px'
  },
  notFoundButton: {
    backgroundColor: COLORS.white,
    border: `3px solid ${COLORS.lightGreen}`,
    width: '60%',
    borderRadius: '55px',
    marginTop: '60px',
    height: '89px'
  },
  notFoundButtonText: {
    color: COLORS.lightGreen,
    fontSize: '25px',
    textTransform: 'initial'
  },
  parkingSuggestText: {
    fontWeight: 'normal'
  },
  footer: {
    width: '100%',
    position: 'absolute',
    bottom: '0px'
  },
  footerContent: {
    zIndex: '1',
    marginLeft: '40px',
    marginTop: '40px',
    width: '460px',
    position: 'absolute',
    bottom: '25%'
  },
  footerContentM1: {
    fontSize: '30px',
    display: 'block',
    fontWeight: 'bold',
    marginBottom: '20px'
  },
  footerContentM2: {
    fontSize: '25px',
    marginTop: '20px',
    display: 'block',
    width: '390px',
    color: COLORS.grey
  },
  footerContentM3: {
    fontSize: '25px',
    color: COLORS.black
  },
  smallFooterButton: {
    backgroundColor: COLORS.lightGreen,
    width: '90%',
    marginLeft: '5%',
    marginBottom: '5%',
    borderRadius: '55px',
    height: '89px'
  },
  footerButton: {
    backgroundColor: COLORS.lightGreen,
    width: '410px',
    borderRadius: '55px',
    marginTop: '40px',
    height: '59px'
  },
  footerText: {
    color: COLORS.white,
    fontSize: '1.3em',
    marginLeft: '15px',
    textTransform: 'initial'
  },
  footerBack: {
    width: '100%'
  },

  footerBackMan: {
    position: 'absolute',
    marginTop: '55px',
    width: '40%',
    right: 0,
    bottom: '0px'
  },

  // MPC-DEV
  smallTitleText: {
    color: palette.primary.white,
    textAlign: 'center',
    fontSize: '1.3em',
    marginTop: '30px'
  },
  titleText: {
    color: palette.primary.white,
    textAlign: 'center',
    fontSize: '3em',
    marginTop: '70px'
  },

  titleTextBlack: {
    paddingTop: 20,
    color: palette.primary.black,
    textAlign: 'center',
    fontSize: '25px'
  },

  textBlack: {
    color: palette.primary.black,
    fontSize: '14px',
    textAlign: 'center'
  },

  container: {
    width: '100%',
    height: '100%',
    background: '#FFFFFF'
  },

  smallHeaderContainer: {
    width: '100%',
    height: '45%',
    display: 'block'
  },

  headerContainer: {
    width: '100%',
    height: '25%',
    display: 'block'
  },

  smallHeaderTopTitle: {
    width: '100%',
    paddingLeft: '2%',
    background: palette.primary.topHeader
  },

  headerTopTitle: {
    width: '100%',
    paddingLeft: '2%',
    background: palette.primary.topHeader
  },

  headerBottomTitle: {
    width: '100%',
    height: '21%',
    background: palette.primary.bottomHeader
  },

  inputContainer: {
    width: '100%',
    height: '11%',
    marginTop: '-160px',
    marginBottom: '50px'
  },

  textInput: {
    width: '60%',
    float: 'left',
    marginLeft: '15%'
  },

  centerContainer: {
    width: '100%',
    height: '20%',
    display: 'block'
  },

  centerItem: {
    width: '100%',
    height: '100%'
  },

  hr: {
    border: 0,
    marginTop: '20px',
    borderTop: '1px dashed grey'
  },

  buttonParking: {
    width: '100%',
    height: '100%',
    borderRadius: 30,
    padding: 10
  },

  searchIcon: {
    width: '110px',
    height: '110px',
    fontSize: '110px'
  }
};

export default styles;
