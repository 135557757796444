import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Icon, Grid } from '@material-ui/core/';
import { Trans } from 'react-i18next';
import { push } from 'connected-react-router';
import moment from 'moment-timezone';
// import DDlIdioma from '../../components/Idioma/dropDownListIdioma';

import ImgBusqueda from '../../assets/images/img_tiendas.png';
import imgGastronomia from '../../assets/images/img_gastronomia.png';
import imgEncuentraAuto from '../../assets/images/img_encuentra_auto.png';
import { showHideMap } from '../../actions/map';
import { poiStateName } from '../../constants/poiStatus';
// import CentreMap from '../../components/Mapa/centre-map';
import './Resultado.scss';
import {
  stringFormat,
  timeFormat,
  timeZone
} from '../../constants/momentFormat';
import { handleOpen } from '../../helpers/customerServiceHours';
import { TITULOS_RESULTADO_MAPS } from '../../constants/titles';
import { POI_TYPE } from '../../constants/poiType';
import { DIRECTORY } from '../../constants/directories';
import { getTotemData } from '../../config/kiosk';
import config from '../../config/api';

const URL_MAP_JIBESTREAM = config.mapUrl;

moment.tz.setDefault(timeZone);

class ResultadoMapa extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPreload: true,
      urlMap: ''
    };
  }

  static defaultProps = {
    dispatch: null,
    selectedPoi: null,
    // originPath: '',
    language: 'es'
  };

  static propTypes = {
    dispatch: PropTypes.func,
    selectedPoi: PropTypes.object,
    // originPath: PropTypes.string,
    language: PropTypes.string
  };

  componentDidMount = () => {
    const { selectedPoi, dispatch, language } = this.props;
    const { isPreload } = this.state;
    const origin = getTotemData(true).deviceName;

    if (selectedPoi === null) {
      dispatch(push(`/${getTotemData(true).deviceName}/index`));
    }

    if (selectedPoi.atm !== undefined && selectedPoi.atm) {
      if (isPreload) {
        this.setState({
          urlMap: `${URL_MAP_JIBESTREAM}atm-route-direct/pak/${language}/${origin}/${selectedPoi.suc}/${selectedPoi.name}`,
          isPreload: false
        });
      }
    }

    if (selectedPoi.DestinsucParking) {
      if (isPreload) {
        this.setState({
          urlMap: `${URL_MAP_JIBESTREAM}parking-map/pak/${language}/${origin}/${selectedPoi.DestinsucParking}/${selectedPoi.siteFR}`,
          isPreload: false
        });
      }
    }
  };

  handleBack = () => {
    const { dispatch } = this.props;

    dispatch(showHideMap(false));
    document.getElementById('map-holder-control').style.zIndex = -10;
    dispatch(push(`/${getTotemData(true).deviceName}/Index`));
  };

  render() {
    const { selectedPoi } = this.props;
    const { urlMap } = this.state;

    // const heightMap = window.innerHeight - 250;

    const {
      name,
      floor,
      bgColorClass,
      mapTitle,
      mallZone,
      openingTime,
      closingTime,
      poiStateId,
      poiTypeId,
      icon,
      directory
    } = selectedPoi || {};

    let statusText = '';
    let fullTime = '';

    if (openingTime && closingTime && poiStateId === 1) {
      statusText = handleOpen(openingTime, closingTime, true, true);
      fullTime = `${openingTime} - ${closingTime}`;
    } else if (mallZone && poiStateId === 1) {
      const { startTime, endTime } = mallZone;
      const startTimeText = moment(startTime, stringFormat.format1).format(
        timeFormat.format1
      );
      const endTimeText = moment(endTime, stringFormat.format1).format(
        timeFormat.format1
      );

      statusText = handleOpen(openingTime, closingTime, false, true);
      fullTime = `${startTimeText} - ${endTimeText}`;
    }

    if (poiStateId !== 1) {
      statusText = poiStateName[poiStateId];
      fullTime = '';
    }

    const { SERVICES, STORES, RESTAURANTS, STORES_SERVICES } = POI_TYPE;
    const isService = poiTypeId === SERVICES;
    let isStore = poiTypeId === STORES || poiTypeId === STORES_SERVICES;
    let isRestaurant = poiTypeId === RESTAURANTS;

    if (directory && directory === DIRECTORY.STORE) {
      isStore = true;
      isRestaurant = false;
    }

    const isFYC = poiTypeId === 99;

    const servicePoiSelected = selectedPoi.mallZone === undefined;

    return (
      <div className=''>
        <div className={`cabecera ${bgColorClass || 'bg-AZUL'} fnd-tienda`}>
          <div className={`barra ${`${bgColorClass}-2` || 'bg-AZUL-2'}`}>
            <div
              className='btn-volver'
              onClickCapture={this.handleBack}
              style={{ color: 'white' }}
            >
              <Icon className='m-r'>arrow_back</Icon>
              <Trans i18nKey='busqueda_realizar_otra' />
            </div>
            {/* TO DO: por ahora se mantendra oculta la opcion de cambiar de idiomas ya que no estaba funcionando bien
              <div className='posicionar-idioma'>
                <DDlIdioma />
                </div>
              */}
          </div>
          <div className='contenido mapa cabeceraResultadoMapa'>
            <Grid container>
              <Grid item xs={3} className='centrar-cabecera'>
                <div className='wrap-imagen-cabecera'>
                  {isStore && <img alt='Busqueda' src={ImgBusqueda} />}
                  {isRestaurant && <img alt='Busqueda' src={imgGastronomia} />}
                  {isService && !icon && (
                    <img alt='Busqueda' src={ImgBusqueda} />
                  )}
                  {isService && icon && (
                    <div className='icon-tienda'>
                      <div className='demoIcon'>
                        <i className={icon} />
                      </div>
                    </div>
                  )}
                  {isFYC && <img alt='Busqueda' src={imgEncuentraAuto} />}
                </div>
              </Grid>
              <Grid item xs={9} className='centrar-cabecera'>
                <div className='tipo-local m-t'>
                  <Trans i18nKey={TITULOS_RESULTADO_MAPS[mapTitle]} />
                </div>
                <div className='nombre-local'>{name || ''}</div>
                <div className='informacion-adicional'>
                  {(!servicePoiSelected || (!isService && !isFYC)) && (
                    <div className='label'>
                      <Trans i18nKey={`${statusText}`} /> {fullTime}
                    </div>
                  )}
                  {!isService && !isFYC && (
                    <div className='label'>
                      <Trans i18nKey='label_piso' /> {floor || ''}
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        {selectedPoi.atm || selectedPoi.DestinsucParking ? (
          <div className='seccion-mapa-parking'>
            <object
              alt='MapaRuta'
              aria-labelledby='MapaRuta'
              data={urlMap}
              width='100%'
              height='100%'
            />
          </div>
        ) : (
          <div className='seccion-mapa' />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

const mapStateToProps = state => ({
  language: state.app.data.language,
  selectedPoi: state.lists.data.selectedPoi
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultadoMapa);
