import { createActions } from 'reduxsauce';

import {
  getStoresPoisApi,
  getGastronomyPoisApi,
  getServicesPoisApi,
  getStoresCategoriesApi,
  getGastronomyCategoriesApi,
  getServiceCategoriesApi,
  getPoisApiCategory,
  getPoi,
  getCategory
} from '../services/lists';
import { POI_TYPE } from '../constants/poiType';

const { Types, Creators } = createActions(
  {
    setPoisData: ['data'],
    setCategoriesData: ['data'],
    setStatus: ['key', 'value'],
    resetPoisAndCategories: [],
    resetPois: [],
    getPoisData: ['mallId', 'languageId', 'type', 'states'],
    getPoiData: ['mallId', 'poiId', 'languageId'],
    getCategoryData: ['mallId', 'categoryId', 'languageId'],
    getCategoriesData: ['mallId', 'type', 'language'],
    setSelectedPoi: ['data'],
    setMapDevices: ['data'],
    setSelectedOrigen: ['data']
  },
  {
    prefix: 'APP/'
  }
);

const {
  setPoisData,
  setCategoriesData,
  setStatus,
  setSelectedPoi,
  setMapDevices,
  setSelectedOrigen
} = Creators;

const {
  SET_POIS_DATA,
  SET_CATEGORIES_DATA,
  SET_STATUS,
  SET_SELECTED_POI,
  SET_SELECTED_ORIGEN,
  SET_MAP_DEVICES
} = Types;

const resetPoisAndCategories = () => dispatch => {
  dispatch(setPoisData([]));
  dispatch(setCategoriesData([]));
};

const resetPois = () => dispatch => {
  dispatch(setPoisData([]));
};

const selectPoi = poi => dispatch => {
  dispatch(setSelectedPoi(poi));
};

const selecOrigen = origen => dispatch => {
  dispatch(setSelectedOrigen(origen));
};

const getPoiData = (mallId, poiId, language) => dispatch => {
  try {
    dispatch(setStatus('fetching', true));
    dispatch(setStatus('error', false));

    const request = getPoi(mallId, poiId, language);

    return request
      .then(response => {
        dispatch(setStatus('fetching', false));
        dispatch(setStatus('error', false));
        dispatch(setSelectedPoi(response.data));
      })
      .catch(() => {
        dispatch(setStatus('fetching', false));
        dispatch(setStatus('error', true));
        dispatch(setSelectedPoi({}));
      });
  } catch {
    dispatch(setStatus('fetching', false));
    dispatch(setStatus('error', true));
    dispatch(setSelectedPoi({}));

    return undefined;
  }
};

const getCategoryData = (mallId, categoryId, language) => dispatch => {
  try {
    dispatch(setStatus('fetching', true));
    dispatch(setStatus('error', false));

    const request = getCategory(mallId, categoryId, language);

    return request
      .then(response => {
        dispatch(setStatus('fetching', false));
        dispatch(setStatus('error', false));
        dispatch(setSelectedPoi(response.data));
      })
      .catch(() => {
        dispatch(setStatus('fetching', false));
        dispatch(setStatus('error', true));
        dispatch(setSelectedPoi({}));
      });
  } catch {
    dispatch(setStatus('fetching', false));
    dispatch(setStatus('error', true));
    dispatch(setSelectedPoi({}));

    return undefined;
  }
};

const getPoisData = (mallId, languageId, type, states) => dispatch => {
  try {
    dispatch(setStatus('fetching', true));
    dispatch(setStatus('error', false));

    let request = null;

    if (type === POI_TYPE.STORES) {
      request = getStoresPoisApi(mallId, languageId, states);
    } else if (type === POI_TYPE.SERVICES) {
      request = getServicesPoisApi(mallId, languageId, states);
    } else if (type === POI_TYPE.RESTAURANTS) {
      request = getGastronomyPoisApi(mallId, languageId, states);
    } else if (type === POI_TYPE.STORES_SERVICES) {
      request = getServicesPoisApi(mallId, languageId, states);
    }

    return request
      .then(response => {
        dispatch(setStatus('fetching', false));
        dispatch(setStatus('error', false));
        dispatch(setPoisData(response.data));
      })
      .catch(() => {
        dispatch(setStatus('fetching', false));
        dispatch(setStatus('error', true));
        dispatch(setPoisData([]));
      });
  } catch {
    dispatch(setStatus('fetching', false));
    dispatch(setStatus('error', true));
    dispatch(setPoisData([]));

    return undefined;
  }
};

const getCategoriesData = (mallId, type, language) => dispatch => {
  try {
    dispatch(setStatus('fetching', true));

    let request = null;

    if (type === POI_TYPE.STORES) {
      request = getStoresCategoriesApi(mallId, language);
    } else if (type === POI_TYPE.SERVICES) {
      request = getServiceCategoriesApi(mallId, language);
    } else if (type === POI_TYPE.RESTAURANTS) {
      request = getGastronomyCategoriesApi(mallId, language);
    }

    return request
      .then(response => {
        dispatch(setStatus('fetching', false));
        dispatch(setStatus('error', false));
        dispatch(setCategoriesData(response.data));
      })
      .catch(() => {
        dispatch(setStatus('fetching', false));
        dispatch(setStatus('error', true));
        dispatch(setCategoriesData([]));
      });
  } catch {
    dispatch(setStatus('fetching', false));
    dispatch(setStatus('error', true));
    dispatch(setCategoriesData([]));

    return undefined;
  }
};

const getPoisCategory = (categoryId, mallId, states) => dispatch => {
  try {
    dispatch(setStatus('fetching', true));
    dispatch(resetPois());

    const request = getPoisApiCategory(categoryId, mallId, states);

    return request
      .then(response => {
        dispatch(setStatus('fetching', false));
        dispatch(setStatus('error', false));
        dispatch(setPoisData(response.data));
      })
      .catch(() => {
        dispatch(setStatus('fetching', false));
        dispatch(setStatus('error', true));
        dispatch(setPoisData([]));
      });
  } catch {
    dispatch(setStatus('fetching', false));
    dispatch(setStatus('error', true));
    dispatch(setPoisData([]));

    return undefined;
  }
};

export {
  Types,
  SET_POIS_DATA,
  SET_CATEGORIES_DATA,
  SET_STATUS,
  SET_SELECTED_POI,
  SET_MAP_DEVICES,
  SET_SELECTED_ORIGEN,
  resetPoisAndCategories,
  resetPois,
  getPoisData,
  getPoiData,
  getCategoryData,
  getPoisCategory,
  getCategoriesData,
  setMapDevices,
  selectPoi,
  selecOrigen
};

export default Creators;
