import envVariables from './envVariables';

let baseApiUrl = '';
let baseApiUrlArauco = '';
let apiQuercusUrl = '';
let apiParkingUrl = '';
let analyticsId = '';
let analytics4Id = '';
let appEnvironment = 'local';
let mapUrl = '';

export const jibeConfig = {
  customerId: parseInt(envVariables.jibestreamCustomerId, 10),
  clientId: envVariables.jibestreamClientId,
  secret: envVariables.jibestreamSecret,
  venueId: envVariables.jibestreamVenueId
};

const hostname = window && window.location && window.location.hostname;

switch (true) {
  case hostname.includes('localhost') || hostname.includes('kiosk-dev'): {
    baseApiUrl = 'https://api-dev.parauco.com';
    baseApiUrlArauco = 'https://api-dev.araucoplus.cl';
    apiQuercusUrl = `${baseApiUrl}/parking/quercus`;
    apiParkingUrl = `${baseApiUrl}/parkingSpot`;
    analyticsId = 'UA-162285914-1';
    analytics4Id = 'G-4EGHCH024Z';
    appEnvironment = 'local';
    mapUrl = 'https://map-dev.parauco.com/';
    break;
  }
  case hostname.includes('dev') || hostname.includes('development'): {
    baseApiUrl = 'https://api-dev.parauco.com';
    baseApiUrlArauco = 'https://api-dev.araucoplus.cl';
    apiQuercusUrl = `${baseApiUrl}/parking/quercus`;
    apiParkingUrl = `${baseApiUrl}/parkingSpot`;
    analyticsId = 'UA-148492316-3';
    analytics4Id = 'G-4EGHCH024Z';
    appEnvironment = 'dev';
    mapUrl = 'https://map-dev.parauco.com/';
    break;
  }
  case hostname.includes('qa'): {
    baseApiUrl = 'https://api-uat.parauco.com';
    baseApiUrlArauco = 'https://api-uat.araucoplus.cl';
    apiQuercusUrl = `${baseApiUrl}/parking/quercus`;
    apiParkingUrl = `${baseApiUrl}/parkingSpot`;
    analyticsId = 'UA-155172444-1';
    analytics4Id = 'G-4EGHCH024Z';
    appEnvironment = 'qa';
    mapUrl = 'https://map-dev.parauco.com/';
    break;
  }
  case hostname.includes('uat') || hostname.includes('staging'): {
    baseApiUrl = 'https://api-uat.parauco.com';
    baseApiUrlArauco = 'https://api-uat.araucoplus.cl';
    apiQuercusUrl = `${baseApiUrl}/parking/quercus`;
    apiParkingUrl = `${baseApiUrl}/parkingSpot`;
    analyticsId = 'UA-155172444-2';
    analytics4Id = 'G-CLRB5NHRX5';
    appEnvironment = 'uat';
    mapUrl = 'https://map-uat.parauco.com/';
    break;
  }
  case hostname.includes('kiosko.parauco') ||
    hostname.includes('kiosko.araucoplus') ||
    hostname.includes('master') ||
    hostname.includes('main'): {
    baseApiUrl = 'https://api.parauco.com';
    baseApiUrlArauco = 'https://api.araucoplus.cl';
    apiQuercusUrl = `${baseApiUrl}/parking/quercus`;
    apiParkingUrl = `${baseApiUrl}/parkingSpot`;
    analyticsId = 'UA-155172444-3';
    analytics4Id = 'G-DY7KD7CEG6';
    appEnvironment = 'prod';
    mapUrl = 'https://map.parauco.com/';
    break;
  }
  default:
    break;
}

export default {
  baseApiUrl,
  baseApiUrlArauco,
  apiQuercusUrl,
  apiParkingUrl,
  analyticsId,
  analytics4Id,
  mapUrl
};

export const environment = appEnvironment;
