import React from 'react';
import Lottie from 'react-lottie';

import {
  escalatorDownLottie,
  escalatorUPLottie,
  elevatorDownLottie,
  elevatorUpLottie,
  stairsDownLottie,
  stairsUpLottie
} from './constantsImageLottie';
import styles from './style';

const MAP_MOVER_ELEVATOR_ID = 2;
const MAP_MOVER_ESCALATOR_ID = 3;
const MAP_MOVER_STAIRS_ID = 4;

export const getImgModalUp = (moverId, messageModal) => {
  if (moverId === MAP_MOVER_ESCALATOR_ID) {
    return messageModal ? (
      <div style={styles.modal}>
        <Lottie options={escalatorUPLottie} width={600} height={600} />
        <span style={styles.modalTitle}>Sube las escaleras mecánicas</span>
      </div>
    ) : (
      <div style={styles.modal}>
        <Lottie options={escalatorDownLottie} width={600} height={600} />
        <span style={styles.modalTitle}>Baja las escaleras mecánicas</span>
      </div>
    );
  }

  if (moverId === MAP_MOVER_ELEVATOR_ID) {
    return messageModal ? (
      <div style={styles.modal}>
        <Lottie options={elevatorUpLottie} width={600} height={600} />
        <span style={styles.modalTitle}>Sube en el ascensor</span>
      </div>
    ) : (
      <div style={styles.modal}>
        <Lottie options={elevatorDownLottie} width={600} height={600} />
        <span style={styles.modalTitle}>Baja en el ascensor</span>
      </div>
    );
  }

  if (moverId === MAP_MOVER_STAIRS_ID) {
    return messageModal ? (
      <div style={styles.modal}>
        <Lottie options={stairsUpLottie} width={600} height={600} />
        <span style={styles.modalTitle}>Sube las escaleras</span>
      </div>
    ) : (
      <div style={styles.modal}>
        <Lottie options={stairsDownLottie} width={600} height={600} />
        <span style={styles.modalTitle}>Baja las escaleras</span>
      </div>
    );
  }

  return <span>No se tiene mensaje</span>;
};

export const replaceCharacters = value =>
  value ? value.replace(/[ ,-]/g, '').toLowerCase() : value;
