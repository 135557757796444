import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import { Icon, Tab, Tabs, AppBar, CircularProgress } from '@material-ui/core/';
import { Link } from 'react-router-dom';

// import DDlIdioma from '../../components/Idioma/dropDownListIdioma';
import InputBuscadorDirectorio from '../../components/Buscador/InputBuscadorDirectorio';
import Lista from '../../components/Lista/List';
import {
  getPoisData,
  getCategoriesData,
  selecOrigen,
  resetPois
} from '../../actions/lists';
import { getMall, getTotemData, getDevice } from '../../config/kiosk';
import generalStyles from '../../styles/generic';
import Error from '../../components/Error/Error';
import NoResults from '../../components/Buscador/NoResults';
import './Main.scss';
import { ACTION, CATEGORY } from '../../constants/googleAnalytics';
import { DIRECTORY } from '../../constants/directories';
import { POI_TYPE } from '../../constants/poiType';

class MainGastronomia extends React.Component {
  constructor(props) {
    super(props);
    const { language } = this.props;

    this.state = {
      pois: [],
      filteredPois: [],
      categories: [],
      filteredCategories: [],
      searchText: '',
      value: 0,
      currentLanguage: language
    };
    this.timer = null;
  }

  static defaultProps = {
    dispatch: null,
    fetching: false,
    dataPois: [],
    dataCategories: [],
    language: '',
    selectOrigen: '',
    error: false
  };

  static propTypes = {
    dispatch: PropTypes.func,
    fetching: PropTypes.bool,
    dataPois: PropTypes.array,
    dataCategories: PropTypes.array,
    mainSearchResult: PropTypes.object.isRequired,
    language: PropTypes.string,
    selectOrigen: PropTypes.string,
    error: PropTypes.bool,
    match: PropTypes.any.isRequired
  };

  componentDidMount = () => {
    const { selectOrigen, match } = this.props;
    const { params } = match;
    const { origin } = params;

    getDevice(origin);

    if (selectOrigen === 'categoryGastronomia') {
      this.setState({
        value: 1
      });
    }

    this.getPoisAndCategories();
  };

  componentWillUnmount = () => {
    const { dispatch } = this.props;

    dispatch(selecOrigen(''));
  };

  componentDidUpdate(prevProps, prevState) {
    const { language } = this.props;
    const { currentLanguage } = prevState;

    if (currentLanguage !== language) {
      this.getPoisAndCategories();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { dataPois, dataCategories } = nextProps;
    const { filteredPois, filteredCategories, searchText } = prevState;

    const newPois = dataPois !== null ? dataPois[0] : [];
    const newCategories = dataCategories !== null ? dataCategories[0] : [];

    const newFilteredPois =
      filteredPois && searchText !== '' ? filteredPois : newPois;

    const newFilteredCategories =
      filteredCategories && searchText !== ''
        ? filteredCategories
        : newCategories;

    return {
      pois: newPois,
      filteredPois: newFilteredPois,
      categories: newCategories,
      filteredCategories: newFilteredCategories
    };
  }

  getPoisAndCategories = () => {
    const { dispatch, language } = this.props;

    const mallId = getMall();

    // eslint-disable-next-line no-nested-ternary
    let languageId = language === 'en' ? 1 : language === 'es' ? 2 : 3;

    languageId = 2;

    dispatch(
      getPoisData(mallId, languageId, POI_TYPE.RESTAURANTS, [1, 3, 4, 6])
    );

    dispatch(getCategoriesData(mallId, POI_TYPE.RESTAURANTS, language));
    this.setState({ currentLanguage: language });
  };

  handleChange = (event, value) => {
    const { dispatch, language } = this.props;

    // eslint-disable-next-line no-nested-ternary
    let languageId = language === 'en' ? 1 : language === 'es' ? 2 : 3;

    languageId = 2;

    this.setState({ value, searchText: '' });

    if (value === 1) {
      dispatch(resetPois());

      ReactGA.event({
        category: `(MallId = ${getMall()}) ${CATEGORY.DIRECTORIO_GASTRONOMIA};`,
        action: `${ACTION.G_CLICK_CATEGORIA};`,
        label: `Totem = ${getTotemData(true).deviceName};`
      });
      ReactGA4.event(`${ACTION.G_CLICK_CATEGORIA}`, {
        category: `(MallId = ${getMall()}) ${CATEGORY.DIRECTORIO_GASTRONOMIA};`,
        label: `Totem = ${getTotemData(true).deviceName};`
      });
    } else {
      dispatch(
        getPoisData(getMall(), languageId, POI_TYPE.RESTAURANTS, [1, 3, 4, 6])
      );
    }
  };

  handleInputChange = event => {
    const searchText = event.target.value;

    this.filterData(searchText);
  };

  handleKeyboardInputChange = event => {
    this.filterData(event);
  };

  cleanString = string =>
    string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  filterData = searchText => {
    const { pois, categories, value } = this.state;

    window.scrollTo({ top: 0 });

    const newSearchText = this.cleanString(searchText);

    this.setState({ searchText: newSearchText });

    if (value === 0) {
      if (pois !== undefined && newSearchText !== undefined) {
        const newFilteredPois = pois.filter(
          p =>
            this.cleanString(p.name)
              .toLowerCase()
              .indexOf(newSearchText.toLowerCase()) !== -1
        );

        this.setState({ filteredPois: newFilteredPois });

        clearTimeout(this.timer);

        if (newSearchText) {
          this.timer = setTimeout(() => {
            ReactGA.event({
              category: `(MallId = ${getMall()}) ${
                CATEGORY.DIRECTORIO_GASTRONOMIA
              };`,
              action: `${ACTION.G_PALABRA_BUSCADA};`,
              label: `Input = ${newSearchText}; Totem = ${
                getTotemData(true).deviceName
              };`
            });
            ReactGA4.event(`${ACTION.G_PALABRA_BUSCADA}`, {
              category: `(MallId = ${getMall()}) ${
                CATEGORY.DIRECTORIO_GASTRONOMIA
              };`,
              label: `Input = ${newSearchText}; Totem = ${
                getTotemData(true).deviceName
              };`
            });

            if (!newFilteredPois.length) {
              ReactGA.event({
                category: `(MallId = ${getMall()}) ${
                  CATEGORY.DIRECTORIO_GASTRONOMIA
                };`,
                action: `${ACTION.G_SIN_RESULTADOS};`,
                label: `Input = ${newSearchText}; Totem = ${
                  getTotemData(true).deviceName
                };`
              });
              ReactGA4.event(`${ACTION.G_SIN_RESULTADOS}`, {
                category: `(MallId = ${getMall()}) ${
                  CATEGORY.DIRECTORIO_GASTRONOMIA
                };`,
                label: `Input = ${newSearchText}; Totem = ${
                  getTotemData(true).deviceName
                };`
              });
            }
          }, 3000);
        }
      }

      if (newSearchText.length === 0) {
        this.setState({ filteredPois: pois });
      }
    } else if (value === 1) {
      if (categories !== undefined && newSearchText !== undefined) {
        const newFilteredCategories = categories.filter(
          c =>
            this.cleanString(c.name)
              .toLowerCase()
              .indexOf(newSearchText.toLowerCase()) !== -1
        );

        this.setState({ filteredCategories: newFilteredCategories });

        clearTimeout(this.timer);

        if (newSearchText) {
          this.timer = setTimeout(() => {
            ReactGA.event({
              category: `(MallId = ${getMall()}) ${
                CATEGORY.DIRECTORIO_GASTRONOMIA
              };`,
              action: `${ACTION.CG_PALABRA_BUSCADA};`,
              label: `Input = ${newSearchText}; Totem = ${
                getTotemData(true).deviceName
              };`
            });
            ReactGA4.event(`${ACTION.CG_PALABRA_BUSCADA}`, {
              category: `(MallId = ${getMall()}) ${
                CATEGORY.DIRECTORIO_GASTRONOMIA
              };`,
              label: `Input = ${newSearchText}; Totem = ${
                getTotemData(true).deviceName
              };`
            });

            if (!newFilteredCategories.length) {
              ReactGA.event({
                category: `(MallId = ${getMall()}) ${
                  CATEGORY.DIRECTORIO_GASTRONOMIA
                };`,
                action: `${ACTION.CG_SIN_RESULTADOS};`,
                label: `Input = ${newSearchText}; Totem = ${
                  getTotemData(true).deviceName
                };`
              });
              ReactGA4.event(`${ACTION.CG_SIN_RESULTADOS}`, {
                category: `(MallId = ${getMall()}) ${
                  CATEGORY.DIRECTORIO_GASTRONOMIA
                };`,
                label: `Input = ${newSearchText}; Totem = ${
                  getTotemData(true).deviceName
                };`
              });
            }
          }, 3000);
        }
      }

      if (newSearchText.length === 0) {
        this.setState({ filteredCategories: categories });
      }
    }
  };

  render() {
    const { filteredPois, filteredCategories, value, searchText } = this.state;
    const { fetching, error, mainSearchResult } = this.props;

    return (
      <div className=''>
        <div className='cabecera bg-ROJO fnd-gastronomia'>
          <div className='barra bg-ROJO-2'>
            <Link
              className='btn-volver'
              to={`/${getTotemData(true).deviceName}/Index`}
            >
              <Icon className='m-r'>arrow_back</Icon>
              <Trans i18nKey='busqueda_patente_btn_inicio' />
            </Link>
            {/* <div className='posicionar-idioma'>
              <DDlIdioma />
            </div>
            */}
          </div>
          <div className='contenido'>
            <div className='titulo-cabecera'>
              <Trans i18nKey='label_tipo_restaurante' />
            </div>
            <div className='contenedor-input'>
              <InputBuscadorDirectorio
                directory={DIRECTORY.GASTRONOMY}
                isCategory={value === 1}
              />
            </div>
            <AppBar position='static' className='estilo-tabs gastronomia'>
              <Tabs
                value={value}
                onChange={this.handleChange}
                classes={{ flexContainer: 'wrap-container-tab' }}
                indicatorColor='primary'
              >
                <Tab
                  id='TabLista'
                  label={<Trans i18nKey='label_restaurantes' />}
                />
                <Tab
                  id='TabCategorias'
                  label={<Trans i18nKey='label_categorias' />}
                />
              </Tabs>
            </AppBar>
          </div>
        </div>
        <div className='wrap-lista-body'>
          {fetching && (
            <CircularProgress style={generalStyles.progressRestaurants} />
          )}
          {error && (
            <Error
              message={
                value === 0 ? (
                  <Trans i18nKey='busqueda_sin_resultados_restaurant' />
                ) : (
                  <Trans i18nKey='busqueda_sin_resultados_categoria' />
                )
              }
            />
          )}
          {!error && value === 0 && (
            <div>
              {filteredPois && filteredPois.length === 0 && (
                <div className='no-results-container'>
                  <NoResults
                    text={
                      <React.Fragment>
                        <Trans i18nKey='smart_search_no_restaurant_results_first' />
                        {` "${searchText}" `}
                        <Trans i18nKey='smart_search_no_store_results_second' />
                      </React.Fragment>
                    }
                  />
                </div>
              )}
              {mainSearchResult.inputText &&
              mainSearchResult.inputText.length > 2 ? null : (
                <Lista
                  showMoreInfo
                  data={filteredPois}
                  tipo={DIRECTORY.GASTRONOMY}
                  modo='listado'
                />
              )}
            </div>
          )}
          {!error && value === 1 && (
            <div>
              {filteredCategories && filteredCategories.length === 0 && (
                <div className='no-results-container'>
                  <NoResults
                    text={
                      <React.Fragment>
                        <Trans i18nKey='smart_search_no_category_results' />
                        {` "${searchText}" `}
                      </React.Fragment>
                    }
                  />
                </div>
              )}
              {mainSearchResult.inputText &&
              mainSearchResult.inputText.length > 2 ? null : (
                <Lista
                  showMoreInfo={false}
                  data={filteredCategories}
                  tipo={DIRECTORY.GASTRONOMY}
                  modo='categorias'
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  fetching: state.lists.status.fetching,
  error: state.lists.status.error,
  dataPois: state.lists.data.pois,
  dataCategories: state.lists.data.categories,
  mainSearchResult: state.search.data.mainSearchResult,
  language: state.app.data.language,
  selectOrigen: state.lists.data.selectedOrigen
});

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(MainGastronomia);
