import React from 'react';
import { connect } from 'react-redux';
import { Grid, Icon } from '@material-ui/core/';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect';

import { getTotemData } from '../../config/kiosk';
import imgMapa from '../../assets/images/Mapa.png';
import imgGallo from '../../assets/images/animales/Gallo.png';
import imgElefante from '../../assets/images/animales/Elefante.png';
import imgCerdo from '../../assets/images/animales/Cerdo.png';
import imgConejo from '../../assets/images/animales/Conejo.png';
import imgPerro from '../../assets/images/animales/Perro.png';
import imgPato from '../../assets/images/animales/Pato.png';
import imgJirafa from '../../assets/images/animales/Jirafa.png';
import imgCaballo from '../../assets/images/animales/Caballo.png';
import imgMariposa from '../../assets/images/animales/Mariposa.png';
import imgPiña from '../../assets/images/frutas/piña.png';
import imgManzana from '../../assets/images/frutas/manzana.png';
import imgNaranja from '../../assets/images/frutas/naranja.png';
import imgUva from '../../assets/images/frutas/uva.png';
import imgPera from '../../assets/images/frutas/pera.png';
import imgLimon from '../../assets/images/frutas/limon.png';
import imgArandano from '../../assets/images/frutas/arandanos.png';
import imgSandia from '../../assets/images/frutas/sandia.png';
import { logError } from '../../helpers/logger';

import './generales.scss';
import './busquedaEstacionamiento.scss';

class BusquedaEstacionamientoImagen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const origin = getTotemData(true).deviceName;

    try {
      return (
        <div>
          <div className='cabecera bg-VERDE cabecera-busqueda-estacionamiento'>
            <div className='barra bg-VERDE-2'>
              <Link className='btn-volver' to={`/${origin}/BusquedaPorPatente`}>
                <Icon className='m-r'>arrow_back</Icon>
                <div>
                  <Trans i18nKey='busqueda_estacionamiento_btn_volver' />
                </div>
              </Link>
            </div>
            <div className='barra-titulo'>
              <h1>
                <Trans
                  i18nKey={
                    isMobile && !isTablet
                      ? 'busqueda_estacionamiento_header_mobile'
                      : 'busqueda_estacionamiento_header'
                  }
                />
              </h1>
            </div>
          </div>
          <div className='contenedor-mapa'>
            <img alt='' src={imgMapa} className='img-mapa' />
          </div>
          <div className='contenedor-listas'>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <h2>
                  <Trans i18nKey='busqueda_estacionamiento_zona_paris_ripley' />
                </h2>
              </Grid>
              <Grid item xs={6}>
                <h2>
                  <Trans i18nKey='busqueda_estacionamiento_zona_blvd_falabella' />
                </h2>
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <Link
                  to={`/${origin}/Carfinder/ResultadoEsta:selGallo`}
                  className='btn-base-animal c-white'
                >
                  <div className='numero izquierda'>6</div>
                  <div className='derecha'>
                    <img alt='' src={imgGallo} className='img-animal' />
                  </div>
                </Link>
              </Grid>
              <Grid item xs={6}>
                <div className='btn-base-animal c-disabled' />
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <Link
                  to={`/${origin}/Carfinder/ResultadoEsta:selConejo`}
                  className='btn-base-animal c-CAFE'
                >
                  <div className='numero izquierda'>5</div>
                  <div className='derecha'>
                    <img alt='' src={imgConejo} className='img-animal' />
                  </div>
                </Link>
              </Grid>
              <Grid item xs={6}>
                <div className='btn-base-animal c-disabled' />
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <Link
                  to={`/${origin}/Carfinder/ResultadoEsta:selElefante`}
                  className='btn-base-animal c-AZUL'
                >
                  <div className='numero izquierda'>4</div>
                  <div className='derecha'>
                    <img alt='' src={imgElefante} className='img-animal' />
                  </div>
                </Link>
              </Grid>
              <Grid item xs={6}>
                <div className='btn-base-animal c-disabled' />
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <Link
                  to={`/${origin}/Carfinder/ResultadoEsta:selCerdo`}
                  className='btn-base-animal c-ROSADO'
                >
                  <div className='numero izquierda'>3</div>
                  <div className='derecha'>
                    <img alt='' src={imgCerdo} className='img-animal' />
                  </div>
                </Link>
              </Grid>
              <Grid item xs={6}>
                <div className='btn-base-animal c-disabled' />
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <Link
                  to={`/${origin}/Carfinder/ResultadoEsta:selPerro`}
                  className='btn-base-animal c-ROJO'
                >
                  <div className='numero izquierda'>2</div>
                  <div className='derecha'>
                    <img alt='' src={imgPerro} className='img-animal' />
                  </div>
                </Link>
              </Grid>
              <Grid item xs={6}>
                <Link
                  to={`/${origin}/Carfinder/ResultadoEsta:selPiña`}
                  className='btn-base-animal c-DORADO'
                >
                  <div className='numero izquierda'>
                    2 <span className='txt-largo'>Express</span>
                  </div>
                  <div className='derecha'>
                    <img alt='' src={imgPiña} className='img-animal' />
                  </div>
                </Link>
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <Link
                  to={`/${origin}/Carfinder/ResultadoEsta:selPato`}
                  className='btn-base-animal c-AMARILLO'
                >
                  <div className='numero izquierda'>1</div>
                  <div className='derecha'>
                    <img alt='' src={imgPato} className='img-animal' />
                  </div>
                </Link>
              </Grid>
              <Grid item xs={6}>
                <Link
                  to={`/${origin}/Carfinder/ResultadoEsta:selPera`}
                  className='btn-base-animal c-VERDE'
                >
                  <div className='numero izquierda'>
                    1 <span className='txt-largo'>Express / Andén</span>
                  </div>
                  <div className='derecha'>
                    <img alt='' src={imgPera} className='img-animal' />
                  </div>
                </Link>
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <Link
                  to={`/${origin}/Carfinder/ResultadoEsta:selJirafa`}
                  className='btn-base-animal c-NARANJO'
                >
                  <div className='numero izquierda'>-1</div>
                  <div className='derecha'>
                    <img alt='' src={imgJirafa} className='img-animal' />
                  </div>
                </Link>
              </Grid>
              <Grid item xs={6}>
                <Link
                  to={`/${origin}/Carfinder/ResultadoEsta:selManzana`}
                  className='btn-base-animal c-ROJO'
                >
                  <div className='numero izquierda'>-1</div>
                  <div className='derecha'>
                    <img alt='' src={imgManzana} className='img-animal' />
                  </div>
                </Link>
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <Link
                  to={`/${origin}/Carfinder/ResultadoEsta:selCaballo`}
                  className='btn-base-animal c-VERDE'
                >
                  <div className='numero izquierda'>-2</div>
                  <div className='derecha'>
                    <img alt='' src={imgCaballo} className='img-animal' />
                  </div>
                </Link>
              </Grid>
              <Grid item xs={6}>
                <Link
                  to={`/${origin}/Carfinder/ResultadoEsta:selUva`}
                  className='btn-base-animal c-MORADO'
                >
                  <div className='numero izquierda'>-2</div>
                  <div className='derecha'>
                    <img alt='' src={imgUva} className='img-animal' />
                  </div>
                </Link>
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <Link
                  to={`/${origin}/Carfinder/ResultadoEsta:selMariposa`}
                  className='btn-base-animal c-CELESTE'
                >
                  <div className='numero izquierda'>-3</div>
                  <div className='derecha'>
                    <img alt='' src={imgMariposa} className='img-animal' />
                  </div>
                </Link>
              </Grid>
              <Grid item xs={6}>
                <Link
                  to={`/${origin}/Carfinder/ResultadoEsta:selLimon`}
                  className='btn-base-animal c-AMARILLO'
                >
                  <div className='numero izquierda'>-3</div>
                  <div className='derecha'>
                    <img alt='' src={imgLimon} className='img-animal' />
                  </div>
                </Link>
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <div className='btn-base-animal c-disabled' />
              </Grid>
              <Grid item xs={6}>
                <Link
                  to={`/${origin}/Carfinder/ResultadoEsta:selSandia`}
                  className='btn-base-animal c-ROSADO'
                >
                  <div className='numero izquierda'>-4</div>
                  <div className='derecha'>
                    <img alt='' src={imgSandia} className='img-animal' />
                  </div>
                </Link>
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <div className='btn-base-animal c-disabled' />
              </Grid>
              <Grid item xs={6}>
                <Link
                  to={`/${origin}/Carfinder/ResultadoEsta:selNaranja`}
                  className='btn-base-animal c-NARANJO'
                >
                  <div className='numero izquierda'>-5</div>
                  <div className='derecha'>
                    <img alt='' src={imgNaranja} className='img-animal' />
                  </div>
                </Link>
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <div className='btn-base-animal c-disabled' />
              </Grid>
              <Grid item xs={6}>
                <Link
                  to={`/${origin}/Carfinder/ResultadoEsta:selArandano`}
                  className='btn-base-animal c-AZUL'
                >
                  <div className='numero izquierda'>-6</div>
                  <div className='derecha'>
                    <img alt='' src={imgArandano} className='img-animal' />
                  </div>
                </Link>
              </Grid>
            </Grid>
          </div>
        </div>
      );
    } catch (err) {
      logError('[BusquedaEstacionamientoImagen].[render]', {
        error: err,
        path: 'src/containers/CarFinder/BusquedaEstacionamientoImagen.jsx',
        totemInfo: getTotemData(true)
      });

      return undefined;
    }
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

export default connect(mapDispatchToProps)(BusquedaEstacionamientoImagen);
