import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

function ButtonFloor({
  items,
  onHandleIsButtonDisabled,
  onHandleIsLevelActive,
  onHandleChangeFloor
}) {
  return (
    <Button
      disabled={onHandleIsButtonDisabled(items.floor)}
      className={`small ${onHandleIsLevelActive(items.floor)}`}
      onClick={onHandleChangeFloor(items.floor)}
      color={items.default}
      variant={items.contained}
    >
      {items.textButton}
    </Button>
  );
}

ButtonFloor.propTypes = {
  items: PropTypes.object.isRequired,
  onHandleIsButtonDisabled: PropTypes.func.isRequired,
  onHandleIsLevelActive: PropTypes.func.isRequired,
  onHandleChangeFloor: PropTypes.func.isRequired
};

export default ButtonFloor;
