const envVariables = {
  jibestreamHost: process.env.REACT_APP_JIBESTREAM_HOST,
  jibestreamCustomerId: process.env.REACT_APP_JIBESTREAM_CUSTOMER_ID,
  jibestreamClientId: process.env.REACT_APP_JIBESTREAM_CLIENT_ID,
  jibestreamSecret: process.env.REACT_APP_JIBESTREAM_SECRET,
  jibestreamVenueId: process.env.REACT_APP_JIBESTREAM_VENUE_ID,
  isTotemFull: process.env.REACT_APP_IS_TOTEM_FULL,
  inactivityCountdown:
    process.env.REACT_APP_INACTIVITY_COUNTDOWN_IN_SECONDS || 10,
  sessionDuration:
    process.env.REACT_APP_INACTIVITY_SESSION_DURATION_IN_SECONDS || 15,
  mapSessionPause:
    process.env.REACT_APP_MAP_SESSION_PAUSE_AFTER_LOAD_IN_SECONDS || 20,
  logGroupName: process.env.REACT_APP_CLOUDWATCH_GROUP_NAME,
  awsAccessKeyId: process.env.REACT_APP_CLOUDWATCH_ACCESS_KEY,
  awsSecretKey: process.env.REACT_APP_CLOUDWATCH_SECRET_ACCESS_KEY,
  awsRegion: process.env.REACT_APP_CLOUDWATCH_REGION,
  soonToClose:
    process.env.REACT_APP_SMART_SEARCH_SOON_TO_CLOSE_INTERVAL_IN_MINUTES,
  nodeEnv: process.env.NODE_ENV,
  mapUrl: process.env.URL_MAP_JIBESTREAM,
  urlAraucoPlusQR: process.env.REACT_APP_URL_ARAUCO_PLUS_QR
};

export default envVariables;
