import { createActions } from 'reduxsauce';

import { setupMap } from '../helpers/map-helper';

const { Types, Creators } = createActions(
  {
    setLoadingMap: ['isLoading'],
    setMapData: ['data'],
    showHideMap: ['data']
  },
  {
    prefix: 'MAP/'
  }
);

const { setMapData, showHideMap, setLoadingMap } = Creators;

const { SET_MAP_DATA, SHOW_HIDE_MAP, SET_LOADING_MAP } = Types;

const loadMapData = () => async dispatch => {
  dispatch(setLoadingMap(true));
  // we use setLoadingMap(false) in centre-map.js after the map is loaded
  const map = await setupMap();

  dispatch(setMapData(map));
};

export {
  Types,
  setMapData,
  showHideMap,
  setLoadingMap,
  SHOW_HIDE_MAP,
  SET_LOADING_MAP,
  SET_MAP_DATA,
  loadMapData
};

export default Creators;
