import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import { createBrowserHistory } from 'history';
import moment from 'moment-timezone';

import i18n from '../../i18n';
import { setLastClickDate, setData } from '../../actions/findYourCar';
import ModalInactividad from '../ModalInactividad';
import {
  getDevice,
  getSession,
  getTotemData,
  getMall
} from '../../config/kiosk';
import api from '../../config/api';
import Map from '../Mapa/Map';
import { showHideMap } from '../../actions/map';
import { ACTION, CATEGORY } from '../../constants/googleAnalytics';
import { timeFormat, timeZone } from '../../constants/momentFormat';

import MainRoute from './MainRoute';

const history = createBrowserHistory();

const _ci = (process.env.CI || '').trim();

moment.tz.setDefault(timeZone);

history.listen(() => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  ReactGA4.send({
    hitType: 'pageview',
    page: window.location.pathname + window.location.search
  });
});

class Main extends React.Component {
  static defaultProps = {
    lastClickDate: null
  };

  constructor(props) {
    super(props);
    this.state = {
      endMapInstructions: false,
      statusModalsQRAPlus: false,
      btnContinue: false,
      idTotem: false
    };

    this.onHandlerStatusModalsQR = this.onHandlerStatusModalsQR.bind();
  }

  static propTypes = {
    language: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    fetching: PropTypes.bool.isRequired,
    lastClickDate: PropTypes.any,
    location: PropTypes.any.isRequired
  };

  componentDidMount = () => {
    const { location } = this.props;
    const { pathname } = location;
    const arrPath = String(pathname).split('/');
    const origin = arrPath[1];

    this.setState({ idTotem: origin });

    getDevice(origin);

    ReactGA.initialize(api.analyticsId, {
      testMode: _ci === 'true',
      gaOptions: {
        deviceName: getDevice().deviceName,
        deviceId: getDevice().deviceId,
        sessionId: getSession()
      }
    });
    ReactGA4.initialize(api.analytics4Id);
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA4.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search
    });
  };

  handleUserAction = () => {
    const { dispatch, lastClickDate } = this.props;

    if (!lastClickDate) {
      const startDate = moment();

      ReactGA.event({
        category: `(MallId = ${getMall()}) ${CATEGORY.PANTALLA_DE_DESCANSO};`,
        action: `${ACTION.CLICK_PANTALLA_DESCANSO};`,
        label: `Totem = ${getTotemData(true).deviceName};`
      });
      ReactGA4.event(`${ACTION.CLICK_PANTALLA_DESCANSO}`, {
        category: `(MallId = ${getMall()}) ${CATEGORY.PANTALLA_DE_DESCANSO};`,
        label: `Totem = ${getTotemData(true).deviceName};`
      });
      dispatch(setLastClickDate(startDate));
    }
  };

  inhabilitar = () => false;

  handleEndMapInstructions = status => {
    this.setState({ endMapInstructions: status });
  };

  handleClose = goTo => {
    const { dispatch, lastClickDate } = this.props;

    if (lastClickDate) {
      const timeInterval = moment
        .utc(moment().diff(moment(lastClickDate)))
        .format(timeFormat.format3);

      ReactGA.event({
        category: `(MallId = ${getMall()}) ${CATEGORY.MODAL_INACTIVIDAD};`,
        action: `${ACTION.MI_CLICK_EN_NO};`,
        label: `Tiempo Sesión=${timeInterval}; Totem = ${
          getTotemData(true).deviceName
        };`
      });
      ReactGA4.event(`${ACTION.MI_CLICK_EN_NO}`, {
        category: `(MallId = ${getMall()}) ${CATEGORY.MODAL_INACTIVIDAD};`,
        label: `Tiempo Sesión=${timeInterval}; Totem = ${
          getTotemData(true).deviceName
        };`
      });
    }

    dispatch(setLastClickDate(new Date()));
    dispatch(setData('language', 'es'));
    dispatch(setLastClickDate(null));

    const pathname = window && window.location && window.location.pathname;

    if (goTo === 'home') {
      dispatch(push(`/${getTotemData(true).deviceName}/Index`));
    } else {
      dispatch(push(`/${getTotemData(true).deviceName}/`));
    }

    if (String(pathname).includes('ResultadoMapa')) {
      dispatch(showHideMap(false));
      document.getElementById('map-holder-control').style.zIndex = -10;
    }
  };

  onHandlerStatusModalsQR = (status, btnHere = false) => {
    this.setState({ statusModalsQRAPlus: status, btnContinue: btnHere });
  };

  render() {
    const { language, fetching } = this.props;
    const {
      endMapInstructions,
      statusModalsQRAPlus,
      btnContinue,
      idTotem
    } = this.state;

    i18n.changeLanguage(language);
    document.oncontextmenu = this.inhabilitar;

    return (
      <React.Fragment>
        <ModalInactividad
          onClose={this.handleClose}
          fetching={fetching}
          onUserAction={this.handleUserAction}
          endMapInstructions={endMapInstructions}
          statusAnotherModals={this.onHandlerStatusModalsQR}
        >
          <MainRoute />
          <Map
            onEndMapInstructions={this.handleEndMapInstructions}
            status={statusModalsQRAPlus}
            statusQrModal={btnContinue}
            idTotemSuc={idTotem}
          />
        </ModalInactividad>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

const mapStateToProps = state => ({
  language: state.app.data.language,
  totemFull: state.app.data.totemFull,
  lastClickDate: state.app.data.lastClickDate,
  fetching:
    state.app.status.fetching ||
    state.lists.status.fetching ||
    state.findYourCar.status.fetching ||
    state.search.status.fetching
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
