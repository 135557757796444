import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { push } from 'connected-react-router';
import { Icon, Grid } from '@material-ui/core/';
import { Link } from 'react-router-dom';
import { isDesktop } from 'react-device-detect';

import {
  getCategoryData,
  getPoisCategory,
  selecOrigen
} from '../../actions/lists';
import { getMall, getTotemData, getDevice } from '../../config/kiosk';
// import DDlIdioma from '../../components/Idioma/dropDownListIdioma';
import Lista from '../../components/Lista/List';

import './Main.scss';

class IntermediaTienda extends React.Component {
  constructor(props) {
    super(props);
    const { language } = this.props;

    this.state = {
      filteredPois: [],
      currentLanguage: language,
      contadorPois: 0,
      nombreCategory: ''
    };
  }

  static defaultProps = {
    fetching: false,
    dataPois: [],
    selectedPoi: null,
    language: ''
  };

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    fetching: PropTypes.bool,
    dataPois: PropTypes.array,
    language: PropTypes.string,
    selectedPoi: PropTypes.object,
    match: PropTypes.any.isRequired
  };

  componentDidMount = () => {
    const { selectedPoi, match } = this.props;
    const { name } = selectedPoi || {};
    const { params } = match;
    const { origin, id } = params;

    getDevice(origin);

    this.getPoisAndCategories(id);
    this.setState({
      nombreCategory: name
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { language, match } = this.props;
    const { params } = match;
    const { id } = params;
    const { currentLanguage } = prevState;

    if (currentLanguage !== language) {
      this.getCategory(id);
      this.getPoisAndCategories(id);
    }
  }

  static getDerivedStateFromProps(nextProps) {
    const { dataPois, selectedPoi } = nextProps;

    if (dataPois?.length > 0) {
      return {
        filteredPois: dataPois[0],
        contadorPois: dataPois[1],
        nombreCategory: selectedPoi ? selectedPoi.name : ''
      };
    }

    return null;
  }

  getCategory = categoryId => {
    const { dispatch, language } = this.props;
    const mallId = getMall();

    dispatch(getCategoryData(mallId, categoryId, language));
  };

  getPoisAndCategories = idCategory => {
    const { dispatch, language } = this.props;
    const mallId = getMall();

    dispatch(getPoisCategory(idCategory, mallId, [1, 3, 4, 6]));
    this.setState({ currentLanguage: language });
  };

  backToCategory = origen => {
    const { dispatch } = this.props;
    const txtOrigen = origen;

    dispatch(selecOrigen(txtOrigen));
    dispatch(push(`/${getTotemData(true).deviceName}/MainTienda`));
  };

  render() {
    const { filteredPois, contadorPois, nombreCategory } = this.state;
    const { fetching, selectedPoi } = this.props;

    const { icon } = selectedPoi || {};

    let textLenght = 32;

    if (isDesktop) {
      textLenght = 50;
    }

    const truncatedName =
      nombreCategory && nombreCategory.length > textLenght
        ? `${nombreCategory.substring(0, textLenght)}...`
        : nombreCategory;

    return (
      <div className=''>
        <div className='cabecera bg-AZUL fnd-tiendas contenedor'>
          <div className='barra bg-AZUL-2'>
            <Link
              className='btn-volver'
              onClick={() => this.backToCategory('categoryTienda')}
            >
              <Icon className='m-r'>arrow_back</Icon>
              <Trans i18nKey='label_intermedia_volver_tiendas' />
            </Link>
            {/* <div className='posicionar-idioma'>
              <DDlIdioma />
            </div>
            */}
          </div>
          <div className='contenido intermedia'>
            <Grid container>
              <Grid item xs={3}>
                <div className='icon-tienda'>
                  <div className='demoIcon'>
                    <i className={icon} />
                  </div>
                </div>
              </Grid>
              <Grid item xs={9} className='centrar-cabecera'>
                <div className='tipo-local'>
                  <Trans i18nKey='label_intermedia_estas_viendo' />
                </div>
                <div className='nombre-local'>
                  {truncatedName || 'Sin nombre'}
                </div>
                <div className='informacion-adicional'>
                  {!fetching ? (
                    <span>
                      {`${contadorPois} `}
                      {contadorPois > 1 ? (
                        <Trans i18nKey='label_intermedia_tienda_plural' />
                      ) : (
                        <Trans i18nKey='label_intermedia_tienda_singular' />
                      )}
                    </span>
                  ) : null}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className='wrap-lista-body'>
          <Lista
            showMoreInfo
            data={filteredPois}
            tipo='tiendas'
            modo='listado'
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  fetching: state.lists.status.fetching,
  dataPois: state.lists.data.pois,
  language: state.app.data.language,
  selectedPoi: state.lists.data.selectedPoi
});

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(IntermediaTienda);
