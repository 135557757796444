import jmap from 'jmap.js';

import envVariables from '../../config/envVariables';

export const FIRST_FLOOR = 4575; // 3354;
export const SECOND_FLOOR = 4578; // 3357;
export const THIRD_FLOOR = 4580; // 3359;

export const ESCALATOR = 'Escalator';
export const STAIRS = 'Stairs';
export const ELEVATOR = 'Elevator';
export const MAP_HOLDER_CONTROL = 'map-holder-control';
export const WAYFINDING_PATH = 'Wayfinding-Path';

export const CONFIG = {
  host: envVariables.jibestreamHost,
  auth: new jmap.core.Auth(
    envVariables.jibestreamClientId,
    envVariables.jibestreamSecret
  ),
  customerId: envVariables.jibestreamCustomerId,
  venueId: envVariables.jibestreamVenueId,
  container: '',
  width: 0,
  height: 0
};

export const MAP_SESSION_PAUSE_AFTER_LOAD_IN_SECONDS =
  envVariables.mapSessionPause;

export const BUTTONS_FLOORS = [
  {
    color: 'default',
    variant: 'contained',
    textButton: 'Piso 3',
    floor: THIRD_FLOOR
  },
  {
    color: 'default',
    variant: 'contained',
    textButton: 'Piso 2',
    floor: SECOND_FLOOR
  },
  {
    color: 'default',
    variant: 'contained',
    textButton: 'Piso 1',
    floor: FIRST_FLOOR
  }
];

export const PUBLIC_PROFILE = 'key-dest-public';
