import { COLORS } from '../constants/colors';
import { METRICS } from '../constants/metrics';

export default {
  modal: {
    width: '80%',
    margin: '30% auto',
    borderRadius: '50px',
    textAlign: 'center',
    padding: '4% 5%',
    fontSize: '3.3vh',
    backgroundColor: COLORS.white
  },
  modalIcon: {
    width: '15vw',
    display: METRICS.display.block,
    margin: 'auto'
  },
  modalTitle: {
    fontSize: '1em',
    fontWeight: 'bold'
  }
};
