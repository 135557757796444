import { createReducer } from 'reduxsauce';
import produce from 'immer';

import {
  SET_DATA,
  SET_STATUS,
  SET_LAST_CLICK_DATE
} from '../actions/findYourCar';
import { SET_MAP_DATA, SHOW_HIDE_MAP, SET_LOADING_MAP } from '../actions/map';
import envVariables from '../config/envVariables';

const { isTotemFull } = envVariables;

const INITIAL_STATE = {
  data: {
    language: 'es',
    lastClickDate: null,
    totemFull: isTotemFull === 'yes',
    activeVenue: null,
    core: null,
    highlightedDestinations: null,
    destinations: null,
    showMap: false
  },
  status: {
    userActive: false,
    isLoadingMap: true,
    refreshing: false,
    fetching: false,
    error: false
  }
};

const setData = produce(({ data }, { key, value }) => {
  data[key] = value;
});

const setStatus = produce(({ status }, { key, value }) => {
  status[key] = value;
});

const setLastClickDate = produce(({ data, status }, { date }) => {
  data.lastClickDate = date;
  status.userActive = !!date;
});

const setMapData = produce(
  (
    { data },
    { data: { activeVenue, core, highlightedDestinations, destinations } }
  ) => {
    data.core = core;
    data.activeVenue = activeVenue;
    data.highlightedDestinations = highlightedDestinations;
    data.destinations = destinations;
  }
);

const showHideMap = produce(({ data }, action) => {
  data.showHideMap = action.data;
});

const setLoadingMap = produce(({ status }, { isLoading }) => {
  status.isLoadingMap = isLoading;
});

const reducer = createReducer(INITIAL_STATE, {
  [SET_DATA]: setData,
  [SET_STATUS]: setStatus,
  [SET_LAST_CLICK_DATE]: setLastClickDate,
  [SET_MAP_DATA]: setMapData,
  [SHOW_HIDE_MAP]: showHideMap,
  [SET_LOADING_MAP]: setLoadingMap
});

export { INITIAL_STATE };
export default reducer;
