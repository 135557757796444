import React from 'react';
import PropTypes from 'prop-types';
import QRCode from 'react-qr-code';
import { Modal } from '@material-ui/core';

// import { isMobile } from 'react-device-detect';
// import imagenMano from '../../assets/images/mano_animada.gif';
import CloseIcon from '../../../assets/images/QRAraucoPlus/Cerrar.png';
import logoArauco from '../../../assets/images/QRAraucoPlus/LogoAruacoPlus.png';
// import QRNull from '../../../assets/images/QRAraucoPlus/QRNullAraucoPlus.png';
import styles from '../../ModalInactividad/styles';
// import m from './ModalQR';
import './ModalQR.scss';

class ModalQR extends React.PureComponent {
  static propTypes = {
    urlFinish: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    openModalSecond: PropTypes.func.isRequired,
    statusSecond: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired
  };

  render() {
    const {
      urlFinish,
      open,
      openModalSecond,
      statusSecond,
      close
    } = this.props;

    return (
      <React.Fragment>
        <Modal open={open} className='modalQR'>
          <div className='modal-body' style={styles.modal}>
            <img
              src={CloseIcon}
              alt='Close'
              className='closeQRF'
              onClick={() => close()}
            />
            <div className='img-error'>
              <img src={logoArauco} alt='' />
            </div>

            {/* <div className='img-error'>
              <img src={QRNull} className='QRNull' alt='' />
            </div> */}
            <div className='text'>
              <p className='titleQR'>Lleva tu ruta en tu celular</p>
              <p className='subTitleQR'>
                Escanea el QR para abrir la ruta en tu dispositivo móvil.
              </p>
              <button
                type='button'
                className='btn btn-aceptar'
                onClick={() => openModalSecond()}
              >
                Mostrar QR
              </button>
            </div>
          </div>
        </Modal>

        <Modal open={statusSecond} className='modalQRReal'>
          <div className='modal-body' style={styles.modal}>
            <img
              src={CloseIcon}
              alt='Close'
              className='closeQRR'
              onClick={() => close()}
            />
            <div className='img-error'>
              <QRCode value={`${urlFinish}`} />
            </div>
            <div className='text'>
              <p className='subTitleQR'>
                Escanea este QR para llevar la ruta en tu celular.
              </p>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalQR;
