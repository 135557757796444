import { COLORS } from '../constants/colors';

const styles = {
  error: {
    color: COLORS.error,
    marginBottom: 0
  },
  progress: {
    width: '60px',
    height: '60px',
    zIndex: 2,
    position: 'absolute',
    marginTop: '40%',
    marginLeft: 'calc(50% - 60px)'
  },
  progressStore: {
    width: '60px',
    height: '60px',
    position: 'absolute',
    marginTop: '40%',
    marginLeft: 'calc(50% - 60px)',
    color: '#005ace'
  },
  progressRestaurants: {
    width: '60px',
    height: '60px',
    position: 'absolute',
    marginTop: '40%',
    marginLeft: 'calc(50% - 60px)',
    color: '#FF0800'
  },
  progressServices: {
    width: '60px',
    height: '60px',
    position: 'absolute',
    marginTop: '40%',
    marginLeft: 'calc(50% - 60px)',
    color: '#E6E6E6'
  },
  statusBar: {
    fontSize: '0.7em',
    backgroundColor: COLORS.darkGreen
  }
};

export default styles;
