import { CATEGORIES_POIS } from '../constants/categoriesPois';

export const isCategoryServices = (categories, { selectable }) => {
  if (!categories) {
    return false;
  }

  const { isSelectable, poiTypeId } = categories;

  if (!isSelectable && !selectable && CATEGORIES_POIS.SERVICES === poiTypeId) {
    return true;
  }

  return isSelectable === selectable && CATEGORIES_POIS.SERVICES === poiTypeId;
};

export const getWaypointBySuc = (suc, amenities, maps) => {
  for (let index = 0; index < amenities.length; index++) {
    const amenity = amenities[index];
    const waypoints = maps.getWaypointsByAmenity(amenity);

    if (waypoints.length > 0) {
      const waypointResult = waypoints.find(
        waypoint => waypoint.externalId && waypoint.externalId === suc
      );

      if (waypointResult) {
        return waypointResult;
      }
    }
  }

  return false;
};
