import uuid from 'uuid/v4';

const NO_NAME = 'no-name';

export const getDevice = function(name) {
  let totemId = localStorage.getItem('com.parauco.kiosk.deviceId');
  let totemName = localStorage.getItem('com.parauco.kiosk.deviceName');
  let mallId = localStorage.getItem('com.parauco.kiosk.mallId');

  if (
    totemId === undefined ||
    totemId === '' ||
    totemId === null ||
    totemName === undefined ||
    totemName === '' ||
    totemName === null ||
    (name && totemName !== name)
  ) {
    totemId = `${uuid()}`;
    totemName = name || NO_NAME;

    mallId = '1';

    localStorage.setItem('com.parauco.kiosk.deviceId', totemId);
    localStorage.setItem('com.parauco.kiosk.deviceName', totemName);
    localStorage.setItem('com.parauco.kiosk.mallId', mallId);
  }

  return { totemId, totemName };
};

export const newSession = function() {
  const id = uuid();

  localStorage.setItem('com.parauco.kiosk.sessionId', id);

  return id;
};

export const getSession = function() {
  const id = localStorage.getItem('com.parauco.kiosk.sessionId') || '';

  if (id === '') {
    return newSession();
  }

  return id;
};

export const getMall = function() {
  const id =
    localStorage.getItem('com.parauco.kiosk.mallId') ||
    process.env.REACT_APP_MALL_ID ||
    'default_mall';

  return id;
};

export const getTotemData = function(returnAsObject) {
  const device = getDevice();

  if (typeof returnAsObject === 'boolean' && returnAsObject) {
    return {
      mallId: getMall(),
      deviceName: device.totemName,
      deviceId: device.totemId,
      sessionId: getSession()
    };
  }

  return `mallId=${getMall()};deviceName=${device.totemName};
  deviceId=${device.totemId};sessionId=${getSession()}`;
};
