import { COLORS } from '../../constants/colors';

export default {
  background: {
    height: '100%',
    position: 'fixed',
    display: 'grid',
    gridTemplateRows: '0fr 1fr 1fr 1fr 1fr',
    textAlign: 'center',
    width: '100%',
    fontSize: '3.3vh',
    color: 'white'
  },
  divider: {
    border: '2px solid white'
  },
  firstSecction: {},
  firstText: {
    fontWeight: 'bold',
    fontSize: '4vh',
    marginTop: '10%'
  },
  secondText: {
    fontSize: '2.8vh'
  },
  floorWrapper: {
    display: 'grid',
    margin: 'auto',
    width: '55%',
    gridTemplateRows: '0fr 0fr 0fr 0fr 0fr 0fr 0fr',
    paddingTop: '1.3rem'
  },
  flootTitle: {
    textAlign: 'center',
    fontSize: '2.8vh',
    marginTop: '5%'
  },
  flootNumber: {
    fontSize: '8em',
    fontWeight: 600,
    textAlign: 'center',
    margin: 0
  },
  colorText: {
    fontSize: '1.55em',
    fontWeight: 500,
    textAlign: 'center'
  },
  parkingText: {
    fontWeight: 600,
    whiteSpace: 'nowrap'
  },
  image: {
    height: '20vh',
    marginTop: '1rem'
  },
  backContainer: {
    textAlign: 'left'
  },
  backImage: {
    width: '10%',
    marginLeft: '1rem'
  },
  backText: {
    color: COLORS.white,
    whiteSpace: 'nowrap',
    marginLeft: '15px',
    textTransform: 'initial'
  }
};
