import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import NotConnection from '../../assets/imgs/no_connection.png';

import './Error.scss';
import '../../styles/icons.scss';

class Error extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static defaultProps = {
    className: '',
    message: null
  };

  static propTypes = {
    className: PropTypes.string,
    message: PropTypes.any
  };

  cleanString = string =>
    string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  render() {
    const { className, message } = this.props;

    return (
      <div className={`error-container ${className}`}>
        <h1 className='error'>
          <Trans i18nKey='busqueda_patente_msj_titulo_error' />
        </h1>
        <div className='notFoundMessageContainer'>
          <p className='notFoundMessage'>{message}</p>
        </div>
        <img
          className='notConnectionImage'
          src={NotConnection}
          alt='no_result'
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

const mapStateToProps = state => ({
  language: state.app.data.language
});

export default connect(mapStateToProps, mapDispatchToProps)(Error);
