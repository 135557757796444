import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Button } from '@material-ui/core/';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import { Trans } from 'react-i18next';
import { push } from 'connected-react-router';
import { isMobile, isTablet } from 'react-device-detect';

import InputBuscador from '../../components/Buscador/InputBuscador';
import imgTiendas from '../../assets/images/img_tiendas.png';
import imgCajero from '../../assets/images/img_cajero.png';
import imgTicket from '../../assets/images/img_pagar_ticket.png';
import imgGastronomia from '../../assets/images/img_gastronomia.png';
import imgEncuentraAuto from '../../assets/images/img_encuentra_auto.png';
import imgServicio1 from '../../assets/images/img_servicio_1.png';
import imgServicio2 from '../../assets/images/img_servicio_2.png';
import imgServicioSalidas from '../../assets/images/ic_salidas.png';
import imgServicio4 from '../../assets/images/img_servicio_4.png';
import {
  resetPoisAndCategories,
  getPoisData,
  getCategoriesData,
  selectPoi
} from '../../actions/lists';
import { getServiceCategoriesApi } from '../../services/lists';
import { resetImages } from '../../actions/findYourCar';
import { getMall, getTotemData, getDevice } from '../../config/kiosk';
import './index.scss';
import { ACTION, CATEGORY, LABEL } from '../../constants/googleAnalytics';
import { showHideMap } from '../../actions/map';
import { CATEGORIES_ID_BUTTONS } from '../../constants/categoriesPois';
import { ModalPayByPhone } from '../../components/Modal/ModalPayByPhone';

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resolucion: '',
      modalSearchAtmStatus: false
    };
    this.data = [];
  }

  static defaultProps = {
    dispatch: null,
    language: ''
  };

  static propTypes = {
    dispatch: PropTypes.func,
    language: PropTypes.string,
    match: PropTypes.any.isRequired
  };

  componentDidMount() {
    const { dispatch, match } = this.props;
    const { params } = match;
    const { origin } = params;

    getDevice(origin);

    dispatch(resetImages());
    dispatch(resetPoisAndCategories());

    this.setState({
      resolucion: `${window.screen.width} x ${window.screen.height}`
    });

    this.getCategoriesServices();
  }

  getCategoriesServices = async () => {
    const { language } = this.props;

    const mallId = getMall();

    const { data } = await getServiceCategoriesApi(mallId, language);
    const [objectData] = data;

    this.data = objectData;
  };

  handleCategory = id => {
    const category = this.data.find(item => item.id === id);

    const selectable = category.isSelectable
      ? LABEL.SELECTABLE
      : LABEL.NOT_SELECTABLE;

    ReactGA.event({
      category: `(MallId = ${getMall()}) ${CATEGORY.ACCESOS_DIRECTOS};`,
      action: `${CATEGORY.ACCESOS_DIRECTOS};`,
      label: `Categoría = ${category.name}; Totem = ${
        getTotemData(true).deviceName
      }; ${selectable}`
    });
    ReactGA4.event(`${CATEGORY.ACCESOS_DIRECTOS}`, {
      category: `(MallId = ${getMall()}) ${CATEGORY.ACCESOS_DIRECTOS};`,
      label: `Categoría = ${category.name}; Totem = ${
        getTotemData(true).deviceName
      }; ${selectable}`
    });

    this.goToButtonServices(category);
  };

  sendEventServices = () => {
    ReactGA.event({
      category: `(MallId = ${getMall()}) ${CATEGORY.ACCESOS_DIRECTOS};`,
      action: `${CATEGORY.ACCESOS_DIRECTOS};`,
      label: `Categoría = ${LABEL.OTHER_SERVICES}; Totem = ${
        getTotemData(true).deviceName
      };`
    });
    ReactGA4.event(`${CATEGORY.ACCESOS_DIRECTOS}`, {
      category: `(MallId = ${getMall()}) ${CATEGORY.ACCESOS_DIRECTOS};`,
      label: `Categoría = ${LABEL.OTHER_SERVICES}; Totem = ${
        getTotemData(true).deviceName
      };`
    });
  };

  handleClickFindYourCar = () => {
    ReactGA.event({
      category: `(MallId = ${getMall()}) ${CATEGORY.ACCESOS_DIRECTOS};`,
      action: `${CATEGORY.ACCESOS_DIRECTOS};`,
      label: `Categoría = ${LABEL.FIND_YOUR_CAR}; Totem = ${
        getTotemData(true).deviceName
      };`
    });
    ReactGA4.event(`${CATEGORY.ACCESOS_DIRECTOS}`, {
      category: `(MallId = ${getMall()}) ${CATEGORY.ACCESOS_DIRECTOS};`,
      label: `Categoría = ${LABEL.FIND_YOUR_CAR}; Totem = ${
        getTotemData(true).deviceName
      };`
    });
  };

  goToButtonServices = item => {
    const { dispatch } = this.props;

    const clonedCategory = JSON.parse(JSON.stringify(item));

    let origin = '';
    let destino = '';
    let bgColorClass = '';
    let mapTitle = '';
    let showMapa = false;
    const kioskOrigin = getTotemData(true).deviceName;

    if (item.isSelectable === true) {
      bgColorClass = 'bg-GRIS';
      origin = `/${kioskOrigin}/MainServicios`;
      destino = `/${kioskOrigin}/IntermediaServicios/${item.id}`;
    } else {
      showMapa = true;
      mapTitle = 'SERVICIOS';
      bgColorClass = 'bg-GRIS';
      origin = `/${kioskOrigin}/MainServicios`;
      destino = `/${kioskOrigin}/ResultadoMapa`;
    }

    // incorporación de atributos
    clonedCategory.bgColorClass = bgColorClass;
    clonedCategory.originPath = origin;
    clonedCategory.destinPath = destino;
    clonedCategory.mapTitle = mapTitle;

    dispatch(selectPoi(clonedCategory));
    dispatch(showHideMap(showMapa));
    dispatch(push(destino));
  };

  handleGoToShops = () => {
    ReactGA.event({
      category: `(MallId = ${getMall()}) ${CATEGORY.HOME_BOTON_TIENDAS};`,
      action: `${ACTION.CLICK_LISTA_TIENDAS};`,
      label: `Totem = ${getTotemData(true).deviceName};`
    });
    ReactGA4.event(`${ACTION.CLICK_LISTA_TIENDAS}`, {
      category: `(MallId = ${getMall()}) ${CATEGORY.HOME_BOTON_TIENDAS};`,
      label: `Totem = ${getTotemData(true).deviceName};`
    });
  };

  handleGoToGastronomy = () => {
    ReactGA.event({
      category: `(MallId = ${getMall()}) ${CATEGORY.HOME_BOTON_GASTRONOMIA};`,
      action: `${ACTION.CLICK_LISTA_GASTRONOMIA};`,
      label: `Totem = ${getTotemData(true).deviceName};`
    });
    ReactGA4.event(`${ACTION.CLICK_LISTA_GASTRONOMIA}`, {
      category: `(MallId = ${getMall()}) ${CATEGORY.HOME_BOTON_GASTRONOMIA};`,
      label: `Totem = ${getTotemData(true).deviceName};`
    });
  };

  getPoisAndCategories = () => {
    const { dispatch, language } = this.props;

    const mallId = getMall();

    // eslint-disable-next-line no-nested-ternary
    let languageId = language === 'en' ? 1 : language === 'es' ? 2 : 3;

    languageId = 2;

    dispatch(getPoisData(mallId, languageId, 2, [1, 3, 4]));
    dispatch(getCategoriesData(mallId, 2, language));
  };

  closeModalAtm = () => {
    this.setState({ modalSearchAtmStatus: false });
  };

  handleOpenModal = () => {
    this.setState({ modalSearchAtmStatus: true });
  };

  render() {
    const { resolucion, modalSearchAtmStatus } = this.state;
    const origin = getTotemData(true).deviceName;

    return (
      <div className='fondoPantalla'>
        <div className='resolucion-pantalla'>resolución: {resolucion}</div>
        <div className='cabecera principal'>
          <div className='barra' />
          <div className='contenido'>
            <h3>
              <Trans i18nKey='label_main_title' />
            </h3>
            <div className='contenedor-input'>
              <InputBuscador />
            </div>
          </div>
        </div>
        <div className='seccion-botones'>
          <Grid container spacing={isMobile && !isTablet ? 3 : 5}>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={3}
              xl={3}
              className={`${isMobile && !isTablet ? '' : 'm-t-lg'}`}
            >
              <Link to={`/${origin}/MainTienda`}>
                <Button
                  className='btn-home btn-azul'
                  color='primary'
                  variant='contained'
                  onClick={this.handleGoToShops}
                >
                  <div className='img-icono'>
                    <img src={imgTiendas} alt='' />
                  </div>
                  <div className='txt-icono'>
                    <Trans i18nKey='label_tiendas' />
                  </div>
                </Button>
              </Link>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={3}
              xl={3}
              className={`${isMobile && !isTablet ? '' : 'm-t-lg'}`}
            >
              <Link to={`/${origin}/MainGastronomia`}>
                <Button
                  className='btn-home btn-red'
                  variant='contained'
                  onClick={this.handleGoToGastronomy}
                >
                  <div className='img-icono'>
                    <img src={imgGastronomia} alt='' />
                  </div>
                  <div className='txt-icono'>
                    <Trans i18nKey='label_gastronomia' />
                  </div>
                </Button>
              </Link>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={3}
              xl={3}
              className={`${isMobile && !isTablet ? '' : 'm-t-lg'}`}
            >
              <Link to={`/${origin}/BusquedaPorPatente`}>
                <Button
                  className='btn-home'
                  color='primary'
                  variant='contained'
                  onClick={() => this.handleClickFindYourCar()}
                >
                  <div className='img-icono'>
                    <img src={imgEncuentraAuto} alt='' />
                  </div>
                  <div className='txt-icono'>
                    <Trans i18nKey='label_main_find_car' />
                  </div>
                </Button>
              </Link>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={3}
              xl={3}
              className={`${isMobile && !isTablet ? '' : 'm-t-lg'}`}
            >
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <Button
                    className='btn-home small btn-white'
                    color='default'
                    variant='contained'
                    onClick={() =>
                      this.handleCategory(CATEGORIES_ID_BUTTONS.CLIENT_SERVICE)
                    }
                  >
                    <div className='img-icono'>
                      <img src={imgServicio1} alt='' />
                    </div>
                    <div className='txt-icono'>
                      <Trans i18nKey='label_main_client_service' />
                    </div>
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    className='btn-home small btn-white'
                    color='default'
                    variant='contained'
                    onClick={() =>
                      this.handleCategory(CATEGORIES_ID_BUTTONS.BATHROOMS)
                    }
                  >
                    <div className='img-icono'>
                      <img src={imgServicio2} alt='' />
                    </div>
                    <div className='txt-icono'>
                      <Trans i18nKey='label_main_bathroom' />
                    </div>
                  </Button>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                className={`${isMobile && !isTablet ? '' : 'm-t'}`}
              >
                <Grid item xs={6}>
                  <Button
                    className='btn-home small btn-white'
                    color='default'
                    variant='contained'
                    onClick={() =>
                      this.handleCategory(CATEGORIES_ID_BUTTONS.EXITS)
                    }
                  >
                    <div className='img-icono'>
                      <img src={imgServicioSalidas} alt='' />
                    </div>
                    <div className='txt-icono'>
                      <Trans i18nKey='label_main_exits' />
                    </div>
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Link to={`/${origin}/MainServicios`}>
                    <Button
                      className='btn-home small btn-white'
                      color='default'
                      variant='contained'
                      onClick={() => this.sendEventServices()}
                    >
                      <div className='img-icono'>
                        <img src={imgServicio4} alt='' />
                      </div>
                      <div className='txt-icono'>
                        <Trans i18nKey='label_main_other_services' />
                      </div>
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={3}
              xl={3}
              className={`${isMobile && !isTablet ? '' : 'm-t-lg'}`}
            >
              <Link to={`/${origin}/PerdiMiTicket`}>
                <Button
                  className='btn-home btn-azulverde btn-home-adicional'
                  color='primary'
                  variant='contained'
                >
                  <div className='cuadro-adicional'>
                    <div className='img-icono-adicional'>
                      <img src={imgTicket} alt='' />
                    </div>
                    <div className='txt-icono txt-icono-adicional'>
                      <Trans i18nKey='label_ticket' />
                    </div>
                  </div>
                </Button>
              </Link>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={3}
              xl={3}
              className={`${isMobile && !isTablet ? '' : 'm-t-lg'}`}
            >
              <div onClick={this.handleOpenModal}>
                <Button
                  className='btn-home btn-azulverde btn-home-adicional'
                  color='primary'
                  variant='contained'
                >
                  <div className='cuadro-adicional'>
                    <div className='img-icono-adicional'>
                      <img src={imgCajero} alt='' />
                    </div>
                    <div className='txt-icono txt-icono-adicional'>
                      <Trans i18nKey='label_cajero' />
                    </div>
                  </div>
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
        {modalSearchAtmStatus && <ModalPayByPhone close={this.closeModalAtm} />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.app.data.language
});

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
