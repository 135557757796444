import { createReducer } from 'reduxsauce';
import produce from 'immer';

import {
  SET_PARKING_IMAGES,
  SET_DATA,
  SET_STATUS,
  SET_FLOOR_DATA,
  SET_ALL_FLOOR,
  SET_PATENT
} from '../actions/findYourCar';

const INITIAL_STATE = {
  data: {
    patent: '',
    parkingImages: {},
    floorData: null,
    allFloor: null
  },
  status: {
    refreshing: false,
    fetching: false,
    error: false
  }
};

const setParkingImages = produce(({ data }, { data: parkingImages }) => {
  data.parkingImages = parkingImages;
});

const setData = produce(({ data }, { key, value }) => {
  data[key] = value;
});

const setStatus = produce(({ status }, { key, value }) => {
  status[key] = value;
});

const setFloorData = produce(({ data }, { data: floorData }) => {
  data.floorData = floorData;
});
const setAllFloor = produce(({ data }, { data: allFloor }) => {
  data.allFloor = allFloor;
});

const setPatent = produce(({ data }, { data: license }) => {
  data.patent = license;
});

const reducer = createReducer(INITIAL_STATE, {
  [SET_PARKING_IMAGES]: setParkingImages,
  [SET_DATA]: setData,
  [SET_STATUS]: setStatus,
  [SET_FLOOR_DATA]: setFloorData,
  [SET_ALL_FLOOR]: setAllFloor,
  [SET_PATENT]: setPatent
});

export { INITIAL_STATE };
export default reducer;
