export const label = {
  3: 'en_remodelacion',
  4: 'proximamente',
  6: 'cerrado'
};

export const poiStateName = {
  3: 'En remodelación',
  4: 'Próximamente',
  6: 'Cerrado'
};
