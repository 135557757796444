export const timeZone = 'America/Santiago';

export const stringFormat = {
  format1: 'Hmm',
  format2: 'HHmm'
};

export const timeFormat = {
  format1: 'H:mm',
  format2: 'HH:mm',
  format3: 'HH:mm:ss'
};
