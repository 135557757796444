export const CATEGORY = {
  HOME_BARRA_DE_BUSQUEDA: 'Home - Barra de búsqueda',
  HOME_BOTON_TIENDAS: 'Home - Botón Tiendas',
  HOME_BOTON_GASTRONOMIA: 'Home - Botón Gastronomía',
  DIRECTORIO_TIENDAS_BUSQUEDA: 'Directorio tiendas - Búsqueda',
  DIRECTORIO_GASTRONOMIA_BUSQUEDA: 'Directorio Gastronomía - Búsqueda',
  DIRECTORIO_SERVICIOS_BUSQUEDA: 'Directorio Servicios - Búsqueda',
  DIRECTORIO_TIENDAS: 'Directorio tiendas',
  DIRECTORIO_GASTRONOMIA: 'Directorio Gastronomía',
  DIRECTORIO_SERVICIOS: 'Directorio Servicios',
  MAPA: 'Mapa',
  MODAL_INACTIVIDAD: 'Modal inactividad',
  PANTALLA_DE_DESCANSO: 'Pantalla de descanso',
  ACCESOS_DIRECTOS: 'Home - Botónes de acceso directo'
};

export const ACTION = {
  SS_CLICK_TIENDA: 'Tienda clickeada en resultados (smartsearch)',
  SS_CLICK_CATEGORIA: 'Categoría clickeada en resultados (smartsearch)',
  SS_PALABRA_BUSCADA: 'Palabras buscadas (smartsearch)',
  SS_SIN_TIENDAS: 'Búsquedas sin resultados en Tiendas (smartsearch)',
  SS_SIN_CATEGORIAS: 'Búsquedas sin resultados en Categorías (smartsearch)',
  CLICK_LISTA_TIENDAS: 'Click en botón tiendas del home',
  CLICK_LISTA_GASTRONOMIA: 'Click en botón Gastronomía del home',
  T_CLICK_POI_LISTADO_TIENDAS: 'Click en POI del listado de tiendas (tiendas)',
  T_CATEGORIA_SELECCIONADA: 'Categoría seleccionada (tiendas)',
  T_PALABRA_BUSCADA: 'Palabras buscadas (tiendas)',
  T_SIN_RESULTADOS: 'Búsqueda sin resultados (tiendas)',
  T_CLICK_CATEGORIA: 'Click en tab categoría (tiendas)',
  CLICK_BOTON_LETRAS: 'Click en iniciales scroll',
  CT_PALABRA_BUSCADA: 'Palabras buscadas (categorías de tiendas)',
  CT_SIN_RESULTADOS: 'Búsqueda sin resultados (categorías de tiendas)',
  G_CLICK_POI_LISTADO_GASTRONOMIA:
    'Click en POI del listado gastronomía (Gastronomía)',
  G_CATEGORIA_SELECCIONADA: 'Categoría seleccionada (Gastronomía)',
  G_PALABRA_BUSCADA: 'Palabras buscadas (Gastronomía)',
  G_SIN_RESULTADOS: 'Búsqueda sin resultados (Gastronomía)',
  G_CLICK_CATEGORIA: 'Click en tab categoría (Gastronomía)',
  CG_PALABRA_BUSCADA: 'Palabras buscadas (Categorías de Gastronomía)',
  CG_SIN_RESULTADOS: 'Búsqueda sin resultados (Categorías de Gastronomía)',
  M_REPETIR_RUTA: 'Repetir ruta',
  M_PUNTO_CERCANO: 'Servicio no seleccionable - Búsqueda de punto más cercano',
  MI_CLICK_EN_SI: 'Click en SI (Modal inactividad)',
  MI_CLICK_EN_NO: 'Click en NO - Timeout (Modal inactividad)',
  CLICK_PANTALLA_DESCANSO: 'Click en pantalla de descanso',
  S_CATEGORIA_SELECCIONADA: 'Categoría seleccionada (servicios)'
};

export const LABEL = {
  SELECTABLE: 'Seleccionable = Si',
  NOT_SELECTABLE: 'Seleccionable = No',
  OTHER_SERVICES: 'Otros servicios',
  FIND_YOUR_CAR: 'Encuentra tu auto'
};

export const ACTION_TYPE_LIST = {
  tiendas: '(tiendas)',
  gastronomia: '(gastronomía)',
  servicios: '(servicios)'
};

export const ACTION_TYPE_CATEGORIES = {
  tiendas: '(categorías de tiendas)',
  gastronomia: '(categorías de gastronomía)',
  servicios: '(categorías de servicios)'
};
