import React from 'react';
import { connect } from 'react-redux';
import { IconButton } from '@material-ui/core/';
import { Link } from 'react-router-dom';
import Typical from 'react-typical';
import PropTypes from 'prop-types';

import { getTotemData, getDevice } from '../../config/kiosk';
import imgLogoParque from '../../assets/images/logo_parque_animado.gif';
import imgQRAraucoPlus from '../../assets/images/QR_PAK_TOTEM_DIGITAL.png';
import ElMallEnTuCelular from '../../assets/images/El_mall_en_tu_celular.svg';
import './desansoTotem.scss';

class DescansoTotem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textoAnimado: false,
      listTextoAnimado: []
    };
  }

  static defaultProps = {
    match: null
  };

  static propTypes = {
    match: PropTypes.any
  };

  componentDidMount = () => {
    this.setState({
      textoAnimado: true,
      listTextoAnimado: [
        ' una tienda...',
        1000,
        ' un restaurant...',
        1000,
        ' patio de comidas...'
      ]
    });

    const { match } = this.props;

    if (match) {
      const { params } = match;
      const { origin } = params;

      getDevice(origin);
    }
  };

  render() {
    const { textoAnimado, listTextoAnimado } = this.state;

    return (
      <React.Fragment>
        <Link
          to={`/${getTotemData(true).deviceName}/Index`}
          className='break-screen'
        >
          <div className='fondoPantalla'>
            <div className='logo-parque'>
              <img alt='LogoParqueArauco' src={imgLogoParque} />
            </div>
            <div className='txt-principal-bajada'>
              Busca aquí cómo llegar a
              <br />
              <span className='txt-principal-bajada__bold'>
                {textoAnimado && (
                  <Typical
                    steps={listTextoAnimado}
                    loop={Infinity}
                    wrapper='span'
                  />
                )}
              </span>
            </div>
            <div className='wrap-btn-baliza'>
              <IconButton
                className='btn-baliza btn-animacion-ripple home'
                id='btnBaliza'
              />
            </div>
            <div className='bajada-texto-home'>
              Toca la pantalla para
              <br />
              realizar una búsqueda
            </div>
            <br />
            <div className='line-circle'>
              <div className='body-circle'>
                <div className='logo-container2'>
                  <div className='text-scan'>
                    O ESCANEA EL QR PARA INGRESAR A
                  </div>
                  <div className='text-arauco'>ARAUCOPLUS.CL</div>
                </div>
                <div className='logo-container'>
                  <div className='qr-container'>
                    <img
                      className='qr'
                      src={imgQRAraucoPlus}
                      alt='QR AraucoPlus'
                    />
                  </div>
                  <img
                    className='logo-img'
                    src={ElMallEnTuCelular}
                    alt='El Mall en tu celular'
                  />
                </div>
              </div>
            </div>
          </div>
        </Link>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

export default connect(mapDispatchToProps)(DescansoTotem);
