import { createReducer } from 'reduxsauce';
import produce from 'immer';

import { SET_DATA, SET_STATUS } from '../actions/findYourCar';
import {
  SET_RESULTS,
  RESET_SEARCH,
  SET_LOADING,
  SET_SEARCH_STATUS
} from '../actions/search';

const INITIAL_STATE = {
  data: {
    mainSearchResult: {
      shops: [],
      suggestedShops: [],
      categories: [],
      suggestedCategories: [],
      tags: [],
      relatedShops: [],
      inputText: '',
      fetchResult: false,
      services: [],
      suggestedServices: []
    }
  },
  status: {
    refreshing: false,
    fetching: false,
    error: false
  }
};

const setData = produce(({ data }, { key, value }) => {
  data[key] = value;
});

const setStatus = produce(({ status }, { key, value }) => {
  status[key] = value;
});

const setFetching = produce(({ status }, { loading }) => {
  status.fetching = loading;
});

const setResults = produce(({ data }, { data: results }) => {
  data.mainSearchResult = results;
});

const reset = produce(({ data }) => {
  data.mainSearchResult = {
    shops: [],
    suggestedShops: [],
    categories: [],
    suggestedCategories: [],
    tags: [],
    relatedShops: [],
    inputText: '',
    fetchResult: false,
    services: [],
    suggestedServices: []
  };
});

const reducer = createReducer(INITIAL_STATE, {
  [SET_DATA]: setData,
  [SET_STATUS]: setStatus,
  [SET_SEARCH_STATUS]: setStatus,
  [SET_RESULTS]: setResults,
  [RESET_SEARCH]: reset,
  [SET_LOADING]: setFetching
});

export { INITIAL_STATE };
export default reducer;
