import { COLORS } from '../../constants/colors';
import { METRICS } from '../../constants/metrics';

const buttonModalStyle = {
  width: '30vw',
  fontSize: '0.5em',
  borderRadius: '70px',
  marginTop: '60px',
  height: '5vh'
};

export default {
  modal: {
    width: '80%',
    margin: '30% auto',
    borderRadius: '50px',
    textAlign: 'center',
    padding: '10% 5%',
    fontSize: '3.3vh',
    backgroundColor: COLORS.white
  },
  modalIcon: {
    width: '20vw',
    display: METRICS.display.block,
    margin: 'auto'
  },
  modalTitle: {
    fontSize: '1em'
  },
  modalDescription: {
    fontSize: '0.7em'
  },
  modalWhiteButton: {
    ...buttonModalStyle,
    backgroundColor: COLORS.white,
    color: COLORS.textGrey,
    border: `0.1em solid #E8E8E8`,
    marginRight: '2vw'
  },
  modalGreenButton: {
    ...buttonModalStyle,
    backgroundColor: COLORS.lightGreen,
    color: COLORS.white
  }
};
