import { createReducer } from 'reduxsauce';
import produce from 'immer';

import {
  SET_POIS_DATA,
  SET_CATEGORIES_DATA,
  SET_SELECTED_POI,
  SET_SELECTED_ORIGEN,
  SET_STATUS,
  SET_MAP_DEVICES
} from '../actions/lists';

const INITIAL_STATE = {
  data: {
    pois: [],
    categories: [],
    mapDevices: [],
    selectedPoi: null,
    selectedOrigen: null
  },
  status: {
    fetching: false,
    error: false
  }
};

const setStatus = produce(({ status }, { key, value }) => {
  status[key] = value;
});

const setPoisData = produce(({ data }, { data: pois }) => {
  data.pois = pois;
});

const setSelectedPoi = produce(({ data }, { data: poi }) => {
  data.selectedPoi = poi;
});

const setCategoriesData = produce(({ data }, { data: categories }) => {
  data.categories = categories;
});

const setMapDevices = produce(({ data }, { data: devices }) => {
  data.mapDevices = devices;
});

const setSelectedOrigen = produce(({ data }, { data: origen }) => {
  data.selectedOrigen = origen;
});

const reducer = createReducer(INITIAL_STATE, {
  [SET_STATUS]: setStatus,
  [SET_POIS_DATA]: setPoisData,
  [SET_CATEGORIES_DATA]: setCategoriesData,
  [SET_SELECTED_POI]: setSelectedPoi,
  [SET_MAP_DEVICES]: setMapDevices,
  [SET_SELECTED_ORIGEN]: setSelectedOrigen
});

export { INITIAL_STATE };
export default reducer;
