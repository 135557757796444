import React from 'react';
import { connect } from 'react-redux';
import { Grid, CircularProgress } from '@material-ui/core/';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { Trans } from 'react-i18next';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import { isDesktop } from 'react-device-detect';

import { selectPoi } from '../../actions/lists';
import './List.scss';
import '../../styles/icons.scss';
import { showHideMap } from '../../actions/map';
import { checkCustomerServiceHours } from '../../helpers/customerServiceHours';
import { label } from '../../constants/poiStatus';
import { getMall, getTotemData } from '../../config/kiosk';
import {
  ACTION,
  CATEGORY,
  LABEL,
  ACTION_TYPE_LIST,
  ACTION_TYPE_CATEGORIES
} from '../../constants/googleAnalytics';
import LogoDefault from '../../assets/images/logo_default.png';

class Lista extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spyList: [
        '0',
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'Ñ',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z'
      ],
      clickLista: false,
      topMenuHeight: 40
    };
  }

  static defaultProps = {
    dispatch: null,
    tipo: '',
    modo: '',
    data: [],
    showMoreInfo: false,
    fetching: false,
    showHorarios: true
  };

  static propTypes = {
    dispatch: PropTypes.func,
    tipo: PropTypes.string,
    modo: PropTypes.string,
    data: PropTypes.array,
    showMoreInfo: PropTypes.bool,
    fetching: PropTypes.bool,
    showHorarios: PropTypes.bool
  };

  disableShopShowing = [3, 4, 6];

  componentDidMount() {
    // agregamos el handler scroll
    window.addEventListener('scroll', this.handleOnScroll);
    window.scrollTo({
      top: 0
    });
  }

  componentWillUnmount() {
    // quitamos el handler scroll
    window.removeEventListener('scroll', this.handleOnScroll);
    this.setState({
      clickLista: false
    });
  }

  scrollMov = item => {
    const { topMenuHeight } = this.state;

    // cambiamos los estados
    this.setState({
      clickLista: true
    });

    // quitamos la clase de todos los elementos de la lista
    const elementos = document.getElementsByClassName('lista-scroll');

    for (let i = 0; i < elementos.length; i++) {
      elementos[i].classList.remove('is-active');
    }

    // asignamos la clase solo al elemento clickeado
    const elemento = document.getElementById(`scroll-${item}`);

    elemento.classList.add('is-active');

    // obtenemos ancla y asignamos la altura del elemento
    const strAncla = elemento.getAttribute('data-ancla'); // id del ancla
    const strAnclaLimpia = strAncla.substr(1, 10);
    const seccion = document.getElementById(strAnclaLimpia);
    const topAncla = seccion.offsetTop - topMenuHeight;

    // animamos el scroll
    this.fnScrollTo(topAncla);
  };

  fnScrollTo(topAncla) {
    window.scrollTo({
      top: topAncla,
      behavior: 'smooth'
    });
  }

  onScroll(topAncla2) {
    const reactThis = this;

    // cuando la posicion de la seccion es igual a la posicion de la pagina
    // reseteamos el estado del click para no recorrer nuevamente los numeros
    if (window.pageYOffset === topAncla2) {
      setTimeout(() => {
        // acá se debe pasar a false el estado de clickLista
        reactThis.setState({
          clickLista: false
        });
      }, 200);
    }
  }

  handleOnScroll = () => {
    const { topMenuHeight, clickLista } = this.state;
    const sections = document.querySelectorAll('.template__section');

    // obtiene la sección acntual visible
    const current =
      sections.length -
      [...sections]
        .reverse()
        .findIndex(
          section => window.scrollY >= section.offsetTop - topMenuHeight
        ) -
      1;
    const currentId = sections[current]?.id;
    // activa o inactiva la clase de la lista
    const topAncla2 = sections[current]?.offsetTop - topMenuHeight;

    this.onScroll(topAncla2);

    if (!clickLista) {
      // quitamos la clase de todos los elementos de la lista
      const elementos = document.getElementsByClassName('lista-scroll');

      for (let i = 0; i < elementos.length; i++) {
        elementos[i].classList.remove('is-active');
      }

      // obtendo el ultimo caracter de la seccion
      const idNuevoSel = currentId?.charAt(currentId.length - 1);

      // asignamos la clase solo al elemento clickeado
      const elemento = document.getElementById(`scroll-${idNuevoSel}`);

      if (elemento !== null) {
        elemento.classList.add('is-active');
      }
    }
  };

  trazar = item => {
    const { modo, tipo } = this.props;

    const categoryType = {
      tiendas: CATEGORY.DIRECTORIO_TIENDAS,
      gastronomia: CATEGORY.DIRECTORIO_GASTRONOMIA,
      servicios: CATEGORY.DIRECTORIO_SERVICIOS
    };

    const actionType = {
      listado: ACTION_TYPE_LIST[tipo],
      categorias: ACTION_TYPE_CATEGORIES[tipo]
    };

    ReactGA.event({
      category: `(MallId = ${getMall()}) ${categoryType[tipo]};`,
      action: `${ACTION.CLICK_BOTON_LETRAS} ${actionType[modo]};`,
      label: `Letra = ${item}; Totem = ${getTotemData(true).deviceName};`
    });
    ReactGA4.event(`${ACTION.CLICK_BOTON_LETRAS} ${actionType[modo]}`, {
      category: `(MallId = ${getMall()}) ${categoryType[tipo]};`,
      label: `Letra = ${item}; Totem = ${getTotemData(true).deviceName};`
    });
  };

  goToMap = item => {
    const { dispatch, tipo } = this.props;

    const clonedItem = JSON.parse(JSON.stringify(item));

    const categoryType = {
      tiendas: CATEGORY.DIRECTORIO_TIENDAS,
      gastronomia: CATEGORY.DIRECTORIO_GASTRONOMIA,
      servicios: CATEGORY.DIRECTORIO_SERVICIOS
    };

    const actionType = {
      tiendas: ACTION.T_CLICK_POI_LISTADO_TIENDAS,
      gastronomia: ACTION.G_CLICK_POI_LISTADO_GASTRONOMIA,
      servicios: ACTION.S_CATEGORIA_SELECCIONADA
    };

    if (this.disableShopShowing.includes(item?.poiStateId)) {
      return;
    }

    let origin = '';
    let mapTitle = '';
    let bgColorClass = 'bg-AZUL';
    let destino = '';
    const kioskOrigin = getTotemData(true).deviceName;

    if (tipo === 'tiendas') {
      mapTitle = 'TIENDA';
      origin = `/${kioskOrigin}/MainTienda`;
      destino = `/${kioskOrigin}/ResultadoMapa`;
    } else if (tipo === 'gastronomia') {
      mapTitle = 'GASTRONOMIA';
      bgColorClass = 'bg-ROJO';
      origin = `/${kioskOrigin}/MainGastronomia`;
      destino = `/${kioskOrigin}/ResultadoMapa`;
    } else if (tipo === 'servicios') {
      mapTitle = 'SERVICIOS';
      bgColorClass = 'bg-GRIS';
      origin = `/${kioskOrigin}/MainServicios`;
      destino = `/${kioskOrigin}/ResultadoMapa`;
    }

    ReactGA.event({
      category: `(MallId = ${getMall()}) ${categoryType[tipo]};`,
      action: `${actionType[tipo]};`,
      label: `Poi = ${clonedItem.name}; Totem = ${kioskOrigin};`
    });
    ReactGA4.event(`${actionType[tipo]}`, {
      category: `(MallId = ${getMall()}) ${categoryType[tipo]};`,
      label: `Poi = ${clonedItem.name}; Totem = ${kioskOrigin};`
    });

    clonedItem.mapTitle = mapTitle;
    clonedItem.bgColorClass = bgColorClass;
    clonedItem.originPath = origin;

    dispatch(selectPoi(clonedItem));

    dispatch(showHideMap(true));

    dispatch(push(destino));
  };

  goToIntermedia = item => {
    const { tipo, dispatch } = this.props;

    const clonedCategory = JSON.parse(JSON.stringify(item));

    const categoryType = {
      tiendas: CATEGORY.DIRECTORIO_TIENDAS,
      gastronomia: CATEGORY.DIRECTORIO_GASTRONOMIA,
      servicios: CATEGORY.DIRECTORIO_SERVICIOS
    };

    const actionType = {
      tiendas: ACTION.T_CATEGORIA_SELECCIONADA,
      gastronomia: ACTION.G_CATEGORIA_SELECCIONADA,
      servicios: ACTION.S_CATEGORIA_SELECCIONADA
    };

    if (this.disableShopShowing.includes(item?.poiStateId)) {
      return;
    }

    let origin = '';
    let destino = '';
    let bgColorClass = 'bg-AZUL';
    let mapTitle = '';
    let showMapa = false;
    let selectable = '';
    const kioskOrigin = getTotemData(true).deviceName;

    if (tipo === 'tiendas') {
      origin = `/${kioskOrigin}/MainTienda`;
      destino = `/${kioskOrigin}/IntermediaTienda/${item.id}`;
    } else if (tipo === 'gastronomia') {
      bgColorClass = 'bg-ROJO';
      origin = `/${kioskOrigin}/MainGastronomia`;
      destino = `/${kioskOrigin}/IntermediaGastronomia/${item.id}`;
    } else if (tipo === 'servicios') {
      if (item.isSelectable === true) {
        bgColorClass = 'bg-GRIS';
        origin = `/${kioskOrigin}/MainServicios`;
        destino = `/${kioskOrigin}/IntermediaServicios/${item.id}`;
        selectable = LABEL.SELECTABLE;
      } else {
        showMapa = true;
        mapTitle = 'SERVICIOS';
        bgColorClass = 'bg-GRIS';
        origin = `/${kioskOrigin}/MainServicios`;
        destino = `/${kioskOrigin}/ResultadoMapa`;
        selectable = LABEL.NOT_SELECTABLE;
      }
    }

    ReactGA.event({
      category: `(MallId = ${getMall()}) ${categoryType[tipo]};`,
      action: `${actionType[tipo]};`,
      label: `Categoría = ${item.name}; Totem = ${kioskOrigin}; ${selectable}`
    });
    ReactGA4.event(`${actionType[tipo]}`, {
      category: `(MallId = ${getMall()}) ${categoryType[tipo]};`,
      label: `Categoría = ${item.name}; Totem = ${kioskOrigin}; ${selectable}`
    });

    clonedCategory.bgColorClass = bgColorClass;
    clonedCategory.originPath = origin;
    clonedCategory.destinPath = destino;
    clonedCategory.mapTitle = mapTitle;

    dispatch(selectPoi(clonedCategory));
    dispatch(showHideMap(showMapa));
    dispatch(push(destino));
  };

  cleanString = string =>
    string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  handleBrokenImg = ev => {
    ev.target.src = LogoDefault;
  };

  render() {
    const {
      tipo,
      data,
      showMoreInfo,
      modo,
      fetching,
      showHorarios
    } = this.props;
    const { spyList } = this.state;
    const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    if (fetching) {
      return (
        <div className='wrap-list-fetching loading-map-wrapper '>
          <div className='loading-icon'>
            <CircularProgress />
          </div>
        </div>
      );
    }

    return (
      <div id='lista-completa' className={`${tipo} wrap-lista`}>
        <Grid container spacing={5}>
          <Grid item xs={11}>
            <ul className='store-list'>
              {spyList.map(spyItem => {
                const id = `section_${spyItem}`;
                let itemList = [];

                itemList = data.filter(it => {
                  const cleanedName = this.cleanString(it.name);
                  const firstChar = cleanedName.substring(0, 1).toLowerCase();

                  if (spyItem === '0') {
                    return numbers.indexOf(firstChar) > -1;
                  }

                  return firstChar === spyItem.toLowerCase();
                });

                return (
                  <section key={spyItem} id={id} className='template__section'>
                    {itemList.map((item, index) => {
                      const {
                        logo,
                        name,
                        floor,
                        icon,
                        poiStateId,
                        openingTime,
                        closingTime,
                        mallZone
                      } = item;
                      let stateDescription = '';

                      const isDisabled = this.disableShopShowing.includes(
                        poiStateId
                      );
                      const routeDisabled = isDisabled
                        ? 'route-disabled'
                        : null;
                      let textLenght = 32;

                      if (isDesktop) {
                        textLenght = 50;
                      }

                      const truncatedName =
                        name.length > textLenght
                          ? `${name.substring(0, textLenght).toLowerCase()}...`
                          : name.toLowerCase();

                      if (isDisabled) {
                        stateDescription = (
                          <div className='label fill-red'>
                            <Trans i18nKey={`label_${label[poiStateId]}`} />
                          </div>
                        );
                      }

                      if (poiStateId === 1) {
                        stateDescription = (
                          <div
                            className={` label ${checkCustomerServiceHours(
                              openingTime,
                              closingTime,
                              mallZone
                            )}`}
                          >
                            <Trans
                              i18nKey={`${checkCustomerServiceHours(
                                openingTime,
                                closingTime,
                                mallZone,
                                true
                              )}`}
                            />
                            &nbsp;
                            <span className='text-hours'>
                              {openingTime} - {closingTime}
                            </span>
                          </div>
                        );
                      }

                      return (
                        <li
                          // eslint-disable-next-line react/no-array-index-key
                          key={`itemList_${index}`}
                          onClick={() =>
                            modo !== 'categorias'
                              ? this.goToMap(item)
                              : this.goToIntermedia(item)
                          }
                          className={`${
                            modo !== 'categorias'
                              ? 'item-store'
                              : 'item-category'
                          }`}
                        >
                          <Grid
                            container
                            spacing={0}
                            justify='flex-start'
                            alignItems='center'
                          >
                            <Grid item xs={2}>
                              <div
                                className={
                                  modo !== 'categorias'
                                    ? 'logo-tienda'
                                    : 'icon-tienda'
                                }
                              >
                                {(icon === null || icon === undefined) &&
                                  (logo !== null && logo !== undefined ? (
                                    <img
                                      alt='logo'
                                      className='img-logo'
                                      src={logo.url}
                                      onError={this.handleBrokenImg}
                                    />
                                  ) : (
                                    <img
                                      alt='logo'
                                      className='img-logo'
                                      src={LogoDefault}
                                    />
                                  ))}
                                {icon !== null && icon !== undefined && (
                                  <div className='demoIcon'>
                                    <i className={icon} />
                                  </div>
                                )}
                              </div>
                            </Grid>
                            <Grid item xs={10}>
                              <div
                                className={` ${routeDisabled} nombre-tienda`}
                                data-nameraw={name}
                              >
                                {truncatedName}
                              </div>
                              {showMoreInfo && (
                                <div className='informacion-adicional'>
                                  {showHorarios && stateDescription}
                                  <div className='label label-piso'>
                                    {floor !== null && (
                                      <span>
                                        <Trans i18nKey='label_piso' /> {floor}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              )}
                            </Grid>
                          </Grid>
                        </li>
                      );
                    })}
                  </section>
                );
              })}
            </ul>
          </Grid>
          <Grid item xs={1}>
            <div className='scroll-spy'>
              <ul className='alphabetic-list'>
                {spyList.map(item => {
                  const href = `#section_${item}`;
                  const title = item === '0' ? '#' : item;

                  let itemList = [];

                  itemList = data.filter(it => {
                    const cleanedName = this.cleanString(it.name);
                    const firstChar = cleanedName.substring(0, 1).toLowerCase();

                    if (item === '0') {
                      return numbers.indexOf(firstChar) > -1;
                    }

                    return firstChar === item.toLowerCase();
                  });

                  const newitem =
                    itemList.length > 0
                      ? itemList[0].name.substring(0, 1).toUpperCase()
                      : '';

                  if (newitem !== '') {
                    return (
                      <li key={newitem} onClick={() => this.trazar(newitem)}>
                        <button
                          type='button'
                          className='lista-scroll'
                          data-ancla={href}
                          id={`scroll-${newitem}`}
                          onClick={() => {
                            this.scrollMov(newitem);
                          }}
                        >
                          <span>{title}</span>
                        </button>
                      </li>
                    );
                  }

                  return undefined;
                })}
              </ul>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  fetching: state.lists.status.fetching
});

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Lista);
