import moment from 'moment-timezone';

import { stringFormat, timeZone } from '../constants/momentFormat';

moment.tz.setDefault(timeZone);

export const handleOpen = (opening, closing, isOperationsTool, returnText) => {
  const openingM = moment(
    opening,
    isOperationsTool ? stringFormat.format2 : stringFormat.format1
  );
  const closingM = moment(
    closing,
    isOperationsTool ? stringFormat.format2 : stringFormat.format1
  );

  const isOpen = moment().isBetween(
    moment(
      openingM,
      isOperationsTool ? stringFormat.format2 : stringFormat.format1
    ),
    moment(
      closingM,
      isOperationsTool ? stringFormat.format2 : stringFormat.format1
    ),
    'minutes',
    []
  );

  const isBefore = moment().isBefore(
    moment(
      openingM,
      isOperationsTool ? stringFormat.format2 : stringFormat.format1
    ),
    'minutes'
  );

  if (isOpen) {
    return returnText ? 'label_abierto_ahora' : 'open-or-open-soon';
  }

  if (isBefore) {
    return returnText ? 'label_abre_pronto' : 'open-or-open-soon';
  }

  return returnText ? 'label_cerrado_por_hoy' : 'fill-red';
};

export const checkCustomerServiceHours = (
  openingTime,
  closingTime,
  mallZone,
  returnText = false
) => {
  if (openingTime && closingTime) {
    return handleOpen(openingTime, closingTime, true, returnText);
  }

  if (mallZone) {
    const { startTime, endTime } = mallZone;

    return handleOpen(startTime, endTime, false);
  }

  return null;
};
