import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CarFinded from '../../containers/CarFinded';
import CarFinder from '../../containers/CarFinder2';
import PantallaDescansoGeneralA from '../../containers/PantallaDescanso/descansoTotem';
import PantallaDescansoGeneralB from '../../containers/PantallaDescanso/descansoTotemB';
import Pantalladescanso from '../../containers/PantallaDescanso/desansoCarFinder';
import CarResult from '../../containers/CarFinder2/results';
import BusquedaEstacionamiento from '../../containers/CarFinder2/busquedaEstacionamiento';
import BusquedaEstacionamientoImagen from '../../containers/CarFinder/busquedaEstacionamientoImagen';
import ResultadoEsta from '../../containers/CarFinder2/resultadoEsta';
import IndexTotem from '../../containers/Main';
import ResultadoMapa from '../../containers/ResultadoMapa/Resultado';
import MainTienda from '../../containers/Tiendas/Main';
import MainGastronomia from '../../containers/Gastronomia/Main';
import MainServicios from '../../containers/Servicios/Main';
import IntermediaTienda from '../../containers/Tiendas/Intermedia';
import IntermediaGastronomia from '../../containers/Gastronomia/Intermedia';
import IntermediaServicios from '../../containers/Servicios/Intermedia';
import LostTicket from '../../containers/LostTicket';
import envVariables from '../../config/envVariables';

class MainRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static propTypes = {
    totemFull: PropTypes.bool.isRequired
  };

  render() {
    const { totemFull } = this.props;

    const showOptionA =
      window &&
      window.location &&
      window.location.hostname.indexOf('parauco.com') !== -1;
    const PantallaDescansoGeneral = showOptionA
      ? PantallaDescansoGeneralA
      : PantallaDescansoGeneralB;

    return (
      <React.Fragment>
        <span
          alt='0.12.2'
          id='app-version'
          className={`is-totem-full-${envVariables.isTotemFull}`}
          style={{ display: 'none' }}
        />
        <Switch>
          <Route path='/:origin/Carfinder/finded' component={CarFinded} exact />
          <Route
            path='/:origin/BusquedaPorPatente'
            component={CarFinder}
            exact
          />
          <Route
            path='/:origin/'
            component={totemFull ? PantallaDescansoGeneral : Pantalladescanso}
            exact
          />
          <Route
            path='/:origin/PantallaDescanso/DescansoCarFinder'
            component={Pantalladescanso}
            exact
          />
          <Route
            path='/:origin/Carfinder/Results/:patent'
            component={CarResult}
            exact
          />
          <Route
            path='/:origin/Carfinder/BusquedaEstacionamiento'
            component={BusquedaEstacionamiento}
            exact
          />
          <Route
            path='/:origin/Carfinder/BusquedaEstacionamientoImagen'
            component={BusquedaEstacionamientoImagen}
            exact
          />
          <Route
            path='/:origin/Carfinder/ResultadoEsta:id'
            component={ResultadoEsta}
            exact
            strict
          />
          <Route
            path='/:origin/PantallaDescansoGeneral/descansoTotem'
            component={
              showOptionA ? PantallaDescansoGeneralA : PantallaDescansoGeneralB
            }
            exact
          />
          <Route path='/:origin/Index' component={IndexTotem} exact />
          <Route
            path='/:origin/ResultadoMapa'
            component={ResultadoMapa}
            exact
          />
          <Route path='/:origin/MainTienda' component={MainTienda} exact />
          <Route
            path='/:origin/MainGastronomia'
            component={MainGastronomia}
            exact
          />
          <Route
            path='/:origin/MainServicios'
            component={MainServicios}
            exact
          />
          <Route
            path='/:origin/IntermediaTienda/:id'
            component={IntermediaTienda}
            exact
          />
          <Route
            path='/:origin/IntermediaGastronomia/:id'
            component={IntermediaGastronomia}
            exact
          />
          <Route
            path='/:origin/IntermediaServicios/:id'
            component={IntermediaServicios}
            exact
          />
          <Route
            path='/:origin/atm-route/:stateCash?'
            component={BusquedaEstacionamiento}
            exact
          />
          <Route
            path='/:origin/atm-route/ResultadoEsta:id'
            component={ResultadoEsta}
            exact
            strict
          />
          <Route path='/:origin/PerdiMiTicket' component={LostTicket} exact />
        </Switch>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

const mapStateToProps = state => ({
  totemFull: state.app.data.totemFull
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainRoute)
);
