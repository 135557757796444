/* eslint-disable no-console */
import jmap from 'jmap.js';

import { jibeConfig } from '../config/api';

const host = 'https://dnd0bob6jl1bl.cloudfront.net'; // https://api.jibestream.com';
const { customerId, venueId, clientId, secret } = jibeConfig;

export const setupMap = () => {
  const core = new jmap.core.JCore({
    host,
    auth: new jmap.core.Auth(clientId, secret),
    customerId,
    showAllAmenities: true,
    showAllPathTypes: true,
    rotation: false,
    applyDisplayMode: true
  });

  return new Promise((resolve, reject) => {
    core.populateVenueByVenueId(venueId, (error, activeVenue) => {
      if (error) {
        console.log('error populating active venue', error);

        return reject(error);
      }

      const buildingId = activeVenue.buildings.getDefault().id;

      return core.populateBuildingInVenue(activeVenue, buildingId, err2 => {
        if (err2) {
          console.log('error populating buidling in venue', err2);

          return reject(err2);
        }

        return resolve({
          activeVenue,
          core,
          destinations: activeVenue.destinations.getAll()
        });
      });
    });
  });
};
