import mapEnd from '../../assets/images/img_blank.png';
import mapStart from '../../assets/images/pin.svg';

export const ACTIVE_STYLE = {
  fill: '#0251B7',
  stroke: '#0251B7',
  strokeWidth: 2,
  strokeOpacity: 1
};

export const IN_ACTIVE_STYLE = {
  fill: '#EEEEEE',
  stroke: '#0251B7',
  strokeWidth: 2,
  strokeOpacity: 1
};

export const STYLE_HIGH_LIGHT = {
  fill: '#555555',
  strokeWidth: 0
};

export const PATH_STYLE = {
  stroke: '#005ace',
  strokeOpacity: 0.8,
  strokeWidth: 2
};

export const COLOR_RED_STORE = {
  fill: '#03A9F4'
};

export const ICON_START = {
  url: mapStart,
  width: 50,
  height: 50,
  rotateWithMap: true,
  rotation: 0,
  name: 'start'
};

export const ICON_END = {
  url: mapEnd,
  width: 50,
  height: 50,
  rotateWithMap: true,
  rotation: 0,
  name: 'end'
};
