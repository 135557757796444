import React from 'react';
import { connect } from 'react-redux';
import { IconButton } from '@material-ui/core/';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import imgLogo from '../../assets/images/logo_animado_back.gif';
import imgMascara from '../../assets/images/mascara.png';
import imgBaliza from '../../assets/images/boton_pulsante.gif';
import './desansoCarFinder.scss';
import { getTotemData, getDevice } from '../../config/kiosk';

class DescansoCarFinder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static defaultProps = {
    match: null
  };

  static propTypes = {
    match: PropTypes.any
  };

  componentDidMount = () => {
    const { match } = this.props;

    if (match) {
      const { params } = match;
      const { origin } = params;

      getDevice(origin);
    }
  };

  render() {
    return (
      <React.Fragment>
        <Link to={`/${getTotemData(true).deviceName}/BusquedaPorPatente`}>
          <div className='fondoPantalla'>
            <div className='contenedor-adorno'>
              <div className='seccion-simbolo'>
                <div className='cont-img-logo'>
                  <img alt='' className='img-logo' src={imgLogo} />
                </div>
                <div className='cont-img-mascara'>
                  <img alt='' className='img-mascara' src={imgMascara} />
                </div>
              </div>
              <div className='seccion-boton'>
                <IconButton color='primary'>
                  <img alt='' className='img-boton-baliza' src={imgBaliza} />
                </IconButton>
              </div>
              <div className='bajada-texto'>
                Toca la pantalla <br /> para comenzar
              </div>
            </div>
          </div>
        </Link>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

export default connect(mapDispatchToProps)(DescansoCarFinder);
