import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Fade, Backdrop, Icon } from '@material-ui/core';

import styles from '../ModalInactividad/styles';

class ModalError extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  };

  componentDidUpdate = prevProps => {
    const { open } = this.props;

    if (open && !prevProps.open) {
      setTimeout(() => {
        this.handleClose();
      }, 2000);
    }
  };

  handleClose = () => {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }
  };

  render() {
    const { open, text } = this.props;

    return (
      <Modal
        open={open}
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        onClose={this.handleClose}
        closeAfterTransition
        disableBackdropClick
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className='modal-error-body' style={styles.modal}>
            <div className='img-error'>
              <Icon>error</Icon>
            </div>
            <div className='text'>{text}</div>
          </div>
        </Fade>
      </Modal>
    );
  }
}

export default ModalError;
