import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import app from './redux/app';
import findYourCar from './redux/findYourCar';
import search from './redux/search';
import lists from './redux/lists';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    app,
    findYourCar,
    search,
    lists
  });

export default createRootReducer;
